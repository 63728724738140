import { api } from '@/api/helpers'

export async function postReceipt(body: FormData): Promise<unknown> {
	return api.post('receipts', { body })
}

export async function putNotification(id:string): Promise<unknown> {
	return api.put(`notifications/${id}`, {searchParams: { is_read: 1 }})
}

export async function getSingleReceipt(id:string|any): Promise<Record<string, any>> {
	return api.get('receipts', {searchParams: {receipt_id: id}}).json()
}

export async function putReceipt(id:string, body: object): Promise<Record<string, any>> {
	return api.put(`receipts/${id}`, {searchParams: {...body}})
}

export async function getReceipts(): Promise<Record<string,any>> {
	return api.get('receipts').json()
}

export async function postPackingSlip(body: FormData): Promise<unknown> {
	return api.post('receptions', { body })
}