import Comments from '@/components/forms/Comments'
import Drawing, { useDrawing } from '@/components/forms/Drawing'
import globalContext from '@/context'
import { useFilledTimeEntry } from '@/hooks'
import type { FunctionComponent as FC } from 'preact'
import { useContext, useEffect, useState } from 'preact/hooks'
import { useTranslation } from 'react-i18next'
import { BonusesSelect} from "@/components/forms/MultiSelect";
import DailyReport from '@/components/DailyReport'
import EquipmentList from '@/components/forms/EquipmentList'
import { postDailyReport } from '@/api/reports'
import MaterialList from '@/components/forms/MaterialList'
import DynamicFieldset from '@/components/forms/DynamicFieldset'
import FileInputPreview from '@/components/FileInputPreview'
import { DateTime } from 'luxon'
import { INPUT_DATETIME_FMT } from '@/constants'
import { getEmployeeByMyoctansId } from '@/api/resources'

type Props = {
    path: string
    report_id?: number
    disabled?: boolean
}

const SimplifiedTimeEntry: FC<Props> = (p) => {
    const { t } = useTranslation('daily_report')
    const [state, actions] = useContext(globalContext)
    const timeEntry = useFilledTimeEntry(p.report_id)
    const [images, setImages] = useState<string[]>([])
    const [employee, setEmployee] = useState<Api.Employee>()
    const { setBlob: setSignature, append: appendSignature } = useDrawing(
        'employee_signature',
        'employee_signature.png'
    )

    const submitArgs = {
        async process(fd: FormData) {
            appendSignature(fd)
            if (fd.get('comment')?.toString().trim().length === 0) fd.delete('comment')
            
            // calculate hours and set fields
            const dateIn = DateTime.fromFormat(fd.get('date_in')?.toString() ?? '', INPUT_DATETIME_FMT)
            const dateOut = DateTime.fromFormat(fd.get('date_out')?.toString() ?? '', INPUT_DATETIME_FMT)
            const workedHours = dateOut.diff(dateIn, 'hours').hours.toString()

            fd.append('date', dateIn.toFormat('yyyy-MM-dd'))
            fd.append('time_entries[0][time_in]', dateIn.hour.toString() + ':' + dateIn.minute.toString())
            fd.append('time_entries[0][work_order_time]', workedHours)
            fd.append('time_entries[0][work_order_overtime_15]', '0')
            fd.append('time_entries[0][work_order_overtime]', '0')
            fd.append('time_entries[0][time]', workedHours)
            fd.append('time_entries[0][overtime_15]', '0')
            fd.append('time_entries[0][overtime]', '0')
            fd.append('time_entries[0][pay_time]', workedHours)
            fd.append('time_entries[0][pay_overtime_15]', '0')
            fd.append('time_entries[0][pay_overtime]', '0')

            let travelingTime = fd.get('traveling_time')?.toString() ?? '0'
            if (travelingTime == '') {
                travelingTime = '0'
            }
            fd.append('time_entries[0][traveling_time]', travelingTime)
            fd.append('time_entries[0][traveling_overtime_15]', '0')
            fd.append('time_entries[0][traveling_overtime]', '0')
            fd.append('time_entries[0][billing_traveling_time]', travelingTime)
            fd.append('time_entries[0][billing_traveling_overtime_15]', '0')
            fd.append('time_entries[0][billing_traveling_overtime]', '0')
            fd.append('time_entries[0][payable_traveling_time]', travelingTime)
            fd.append('time_entries[0][payable_traveling_overtime_15]', '0')
            fd.append('time_entries[0][payable_traveling_overtime]', '0')

            fd.append('break_time_am', '00:00')
            fd.append('break_duration_am', '0')
            fd.append('lunch_time', '00:00')
            fd.append('lunch_duration', '0')
            fd.append('break_time_pm', '00:00')
            fd.append('break_duration_pm', '0')
        },
        submit: postDailyReport,
    }

    useEffect(() => {
		if (state?.user?.id) {
			getEmployeeByMyoctansId(state?.user?.id).then((employee: Api.Employee) => {
				setEmployee(employee)
			})
		}
	}, [])

    return (
        
        <DailyReport
            type={'simplified_daily'}
            timeEntry={timeEntry}
            submitArgs={submitArgs}
            translation='daily_report'
            disabled={p.disabled}
            withTraveling={false}
        >
            <input type="hidden" name="time_entries[0][employee_id]" value={employee?.id} />
            <input type="hidden" name="is_from_myoctans" value={1} />
            <input type="hidden" name="time_entries[0][myoctans_type]" value={'simplified_daily'} />
            <hr />
            <label>
                {t('bonuses')}
                <BonusesSelect
                    name='time_entries[0][bonuses][]'
                    defaultValue={timeEntry?.bonuses}
                />
            </label>
            <EquipmentList
                equipments={timeEntry?.equipment}
                translation='daily_report'
                idName='equipment_id'
            />
            <MaterialList
                materials={timeEntry?.materials}
                translation='daily_report'
                idName='material_id'
            />
            <DynamicFieldset
                data={images}
                label={t('pictures')}
                make={i => (
                    <fieldset key={i}>
                        <FileInputPreview 
                            name={`cloudinary_files[${i}][file]`}
                            translation='daily_report'
                         />
                    </fieldset>
                )}
            />
            <Comments
                name='comment'
                defaultValue={timeEntry?.comment?.toString()}
            />
            <Drawing
                label={t('forms:signature')}
                setBlob={setSignature}
            />

        </DailyReport>
        
    )
}
export default SimplifiedTimeEntry;