import { NavGrid, NavItem } from '@/components/NavGrid'
import { usePermission } from '@/hooks'
import { useTranslation } from 'react-i18next'
import ClockIcon from '~icons/bi/clock'
import ListCheckIcon from '~icons/bi/list-check'
import StarsIcon from '~icons/bi/stars'
import TruckIcon from '~icons/bi/truck'
import DrillIcon from '~icons/carbon/drill-through'
import { useQueryClient } from '@tanstack/react-query'
import { getProjects } from '@/api/resources'
import  { useEffect } from 'preact/compat';
import ExcavatorIcon from '~icons/mdi/excavator'

const Index: FC<{ path: string }> = () => {
	const queryClient = useQueryClient()
	const { t } = useTranslation('routes')

	const prefetchProjects = async () => {
		await queryClient.prefetchQuery({
			queryKey: ['projects'],
			queryFn: getProjects
		})
	}

	useEffect(() => {
		prefetchProjects()
	}, [])

	return (
		<NavGrid>
			<NavItem label={t('daily')} href='/reports/daily' icon={ClockIcon} class={usePermission('daily_report','daily_report') ? '' : 'hidden'} />
			<NavItem label={t('simplified_daily')} href='/reports/simplified_daily' icon={ClockIcon} class={usePermission('daily_report','simplified_daily_report') ? '' : 'hidden'} />
			<NavItem label={t('daily_driller')} href='/reports/daily_driller' icon={ClockIcon} class={usePermission('daily_report','driller_daily_report') ? '' : 'hidden'} />
			<NavItem label={t('daily_project')} href='/reports/daily_project' icon={ClockIcon} class={usePermission('daily_report','project_daily_report') ? '' : 'hidden'} />
			<NavItem label={t('daily_tasks')} href='/reports/daily_tasks' icon={ClockIcon} class={usePermission('daily_report','daily_tasks') ? '' : 'hidden'}/>
			<NavItem label={t('filled_forms')} href='/reports/filled' icon={ListCheckIcon} class={usePermission('daily_report','filled_reports') ? '' : 'hidden'} />
			<NavItem label={t('driver')} href='/reports/driver' icon={TruckIcon} class={usePermission('daily_report','driver') ? '' : 'hidden'} />
			<NavItem label={t('driller')} href='/reports/driller' icon={ExcavatorIcon} class={usePermission('daily_report','driller') ? '' : 'hidden'} />
			<NavItem label={t('driller_prod')} href='/reports/driller_prod' icon={ExcavatorIcon} class={usePermission('daily_report','driller_prod') ? '' : 'hidden'} />
			<NavItem label={t('blaster')} href='/reports/blaster' icon={StarsIcon} class={usePermission('daily_report','blaster') ? '' : 'hidden'} />
			<NavItem label={t('shooting_log')} href='/reports/shooting_log' icon={DrillIcon} class={usePermission('daily_report','shooting_log') ? '' : 'hidden'} />
			<NavItem label={t('time_material')} href='/reports/time_material' icon={DrillIcon} class={usePermission('daily_report','time_material') ? '' : 'hidden'}/>
		</NavGrid>
	)
}
export default Index
