import Input from '@/components/forms/Input'
import { useTranslation } from 'react-i18next'
import { ProfessionSelect } from '@/components/forms/FuzzySelect'
import Checkbox from '@/components/forms/Checkbox'
import DynamicFieldset from '@/components/forms/DynamicFieldset'
import { useEffect, useState } from 'preact/hooks'

type Props = {
	defaultValue?: Record<string, any>
	filterKey?: string
	bonus?: Api.Bonus[]
}

const Professions: FC<Props> = ({ ...p }) => {
	const { t } = useTranslation('time_material')
	const [ bonuses, setBonuses ] = useState<Api.Bonus[]>(p.bonus ? p.bonus : [])

	useEffect(() => {
		if (p.bonus) setBonuses(p.bonus)
	}, [p.bonus])

	return (
		<DynamicFieldset
				data={p.defaultValue?.professions}
				label={t('professions')}
				make={(i, d) => (
					<fieldset>
						<ProfessionSelect defaultValue={p.defaultValue?.professions[i].id} label={t('title')} name={`form[professions][${i}][id]`} />

						<Input defaultValue={p.defaultValue?.professions[i].regular_time}  name={`form[professions][${i}][regular_time]`} decimal label={t('regular_time')} className='span2'/>
						<Input defaultValue={p.defaultValue?.professions[i].half_time} name={`form[professions][${i}][half_time]`} decimal label={t('half_time')} className='span2'/>
						<Input defaultValue={p.defaultValue?.professions[i].double_time} name={`form[professions][${i}][double_time]`} decimal label={t('double_time')} className='span2'/>
						{
							bonuses && bonuses.length > 0 ? (
								<label>{t('premiums')}</label>
							) : null
						}
						{
							
							bonuses && bonuses.length > 0 ? (
								bonuses.map((bonus) => (
								<Checkbox name={`form[professions][${i}][bonuses][${bonus.id}]`} label={bonus.name} className='span2' defaultValue={p.defaultValue?.professions[i].bonuses?.[bonus.id]}/>
							))
							) : null
						}

					</fieldset>
			)}
			/>
	)
}
export default Professions