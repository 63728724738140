import { logout } from '@/api/auth'
import globalContext from '@/context'
import classNames from 'classnames'
import { useContext, useState } from 'preact/hooks'
import { useTranslation } from 'react-i18next'
import ArrowLeftIcon from '~icons/bi/arrow-left'
import MenuIcon from '~icons/bi/three-dots-vertical'
import NotificationBadged from '@/components/NotificationBadged'

const NavBar: FC<{ canBack: boolean }> = p => {
	const { t } = useTranslation('menu')
	const [s, actions] = useContext(globalContext)
	const [menu, setMenu] = useState(false)
	function toggleLang(): void {
		if (s.lang.startsWith('en')) actions.setLang('fr')
		else actions.setLang('en')
		setMenu(false)
	}

	async function handleLogout(): Promise<void> {
		setMenu(false)
		try {
			await logout()
		} finally {
			actions.logOut()
		}
	}

	return (
		<nav class='relative mb-6 flex items-center rounded-md border bg-teal-500 px-4 py-2 text-gray-50 shadow-sm'>
			<ArrowLeftIcon
				width='32'
				height='32'
				class={classNames('cursor-pointer', { invisible: !p.canBack })}
				onClick={() => history.back()}
			/>
			<a href='/' class='-my-4 mx-auto brightness-[5] saturate-0 filter'>
				<img src='/logo/banner-light.png' width='192' height='56' alt='logo' />
			</a>
			{s.user && <NotificationBadged />}
			<MenuIcon
				width='28'
				height='28'
				class='cursor-pointer justify-self-end'
				onClick={() => setMenu(true)}
			/>
			{menu && (
				<>
					<div
						class='fixed inset-0 z-40 cursor-pointer bg-black opacity-5'
						onClick={() => setMenu(false)}
					/>
					<ol class='absolute top-14 right-0 z-50 flex w-48 flex-col items-center gap-4 rounded-md bg-white py-4 text-gray-800 shadow-md'>
						{s.user && <li class='text-gray-500'>{s.user.email}</li>}
						{s.apiToken && (
							<li class='menu-item text-red-500' onClick={handleLogout}>
								{t('logout')}
							</li>
						)}
						<li class='menu-item' onClick={toggleLang}>
							{t('change_language')}
						</li>
					</ol>
				</>
			)}
		</nav>
	)
}
export default NavBar
