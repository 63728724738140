
import Input from '@/components/forms/Input'
import { INPUT_DATETIME_FMT } from '@/constants'
import { DateTime } from 'luxon'
import { useTranslation } from 'react-i18next'

type Props = { 
    data?: Api.EwHasEquipmentData
    type?: ReportType 
    taskLabel?: string
    position:any
}

const DailyReportTravelingTimeCard: FC<Props> = ({ data: d,position, ...p }) => {
    const { t } = useTranslation('simplified_daily_report')
    const now = DateTime.now()
    const oneHourAgo = DateTime.now().minus({ hours: 1 })
    const dateIn = fmtDate(oneHourAgo, d?.date_in)
    const dateOut = fmtDate(now, d?.date_out)

    return (
        <>
            <Input
                className='span3'
                required
                max={now.toFormat(INPUT_DATETIME_FMT)}
                defaultValue={dateIn ?? oneHourAgo}
                type='datetime-local'
                name={`transport[${position}][date_in]`}
                label={p.type === 'driver' ? t(`${p.type}:traveling_time_start`) : t('traveling_time_start')}

            />
            <Input
                className='span3'
                required
                max={now.toFormat(INPUT_DATETIME_FMT)}
                defaultValue={dateOut ?? now}
                type='datetime-local'
                name={`transport[${position}][date_out]`}
                label={p.type === 'driver' ? t(`${p.type}:traveling_time_end`) : t('traveling_time_end')}

            />
        </>
    )
}
export default DailyReportTravelingTimeCard

function fmtDate(placeholder: DateTime, dateStr?: string): string {
    const date = dateStr ? DateTime.fromISO(dateStr) : placeholder
    return date.toFormat(INPUT_DATETIME_FMT)
}