import en from '@/en.yaml'
import fr from '@/fr.yaml'
import i18n from 'i18next'
import { initReactI18next } from 'react-i18next'

i18n.use(initReactI18next).init({
	resources: { en, fr },
	lng: 'en',
	interpolation: {
		escapeValue: false,
	},
})

export default i18n
