import { useEffect, useState } from 'preact/hooks'
import { useTranslation } from 'react-i18next'
import DynamicFieldset from './DynamicFieldset'
import Input from './Input'
import { DrillSelect } from '@/components/forms/FuzzySelect'
import VariableUnitsInput from './VariableUnitsInput'

type Props = {
	drillingMachines: Api.EwHasEquipment[] | undefined
    translation: string
}

const DrillingMachineList: FC<Props> = (p) => {
	const { t } = useTranslation(p.translation)
	const [ drillingMachines, setDrillingMachines ] = useState<Api.EwHasEquipment[]>([])

    useEffect(() => {
        if (p.drillingMachines) {
            setDrillingMachines(p.drillingMachines.filter((val) => val.category == 'drilling_machine'))
        }
    }, [p.drillingMachines])

	return (
		<div class='mt-6'>
            <DynamicFieldset
                data={drillingMachines}
                label={t('select_drill')}
                make={i => (
                    <fieldset>
                        <DrillSelect
                            required
                            name={`drilling_machine[${i}][id]`}
                            label={t('select_drill')}
                            filterkey={'daily_report'}
                            defaultValue={drillingMachines[i]?.data?.id.toString()}
                        />
                        <Input
                            className='span3'
                            required
                            integer
                            name={`drilling_machine[${i}][start_time]`}
                            label={t('start_time')}
                            defaultValue={drillingMachines[i]?.data?.start_time.toString()}
                        />
                        <Input
                            className='span3'
                            required
                            integer
                            name={`drilling_machine[${i}][end_time]`}
                            label={t('end_time')}
                            defaultValue={drillingMachines[i]?.data?.end_time.toString()}
                        />
                        <VariableUnitsInput
                            choices='litersOrGallons'
                            name={`drilling_machine[${i}][fuel_liters]`}
                            label={t('fuel')}
                            defaultValue={drillingMachines[i]?.data?.fuel_liters.toString()}
                        />
                    </fieldset>
                )}
            ></DynamicFieldset>
        </div>
        
		
	)

}
export default DrillingMachineList
