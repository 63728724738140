import globalContext from '@/context'
import { useContext } from 'preact/hooks'
import CloseIcon from '~icons/bi/x'

const msgStyles = {
	error: 'text-red-700',
	info: 'text-blue-700',
}

const Toast: FC<{ msg: Message }> = ({ msg }) => {
	const [, actions] = useContext(globalContext)
	return (
		<div className='pointer-events-auto flex h-min w-64 items-center justify-between rounded-lg border bg-gray-50 px-4 py-4 shadow-lg'>
			<div class={msgStyles[msg.type] + ' mx-auto text-sm font-medium'} dangerouslySetInnerHTML={{ __html: msg.str }}></div>
			<div className="p-2"><CloseIcon onClick={() => actions.clearMessage(msg)} /></div>
		</div>
	)
}

interface Props {
	messages: Message[]
}

const Toasts: FC<Props> = p => {
	return p.messages.length > 0 ? (
		<div class='pointer-events-none fixed inset-x-0 bottom-8 flex h-full flex-col-reverse items-center gap-4'>
			{p.messages.slice(0, 4).map((m, i) => (
				<Toast key={m.str + i} msg={m} />
			))}
		</div>
	) : null
}

export default Toasts
