import { postDailyReport } from '@/api/reports'
import { getEmployeeByMyoctansId, getTasks } from '@/api/resources'
import Btn from '@/components/forms/Btn'
import Comments from '@/components/forms/Comments'
import Drawing, { useDrawing } from '@/components/forms/Drawing'
import Input from '@/components/forms/Input'
import TimePicker from '@/components/forms/TimePicker'
import { EquipmentSelect, ProjectSelect, ProjectWithTaskSelect, TaskSelect } from '@/components/forms/FuzzySelect'
import { INPUT_DATETIME_FMT } from '@/constants'
import globalContext from '@/context'
import { useFilledDailyReport, useSubmit } from '@/hooks'
import { hoursToTime } from '@/utils'
import { groupBy } from 'lodash-es'
import { DateTime } from 'luxon'
import type { FunctionComponent as FC } from 'preact'
import { StateUpdater, useContext, useEffect, useState } from 'preact/hooks'
import { useTranslation } from 'react-i18next'
import { useQueryClient } from '@tanstack/react-query'


type DailyTaskForm = {
	id: number | undefined,
	project_id: number | undefined,
	created_by: number | undefined,
	blasting_number: string,
	date: DateTime,
	break_time_am: string,
	break_duration_am: number,
	lunch_time: string,
	lunch_duration: number,
	break_time_pm: string,
	break_duration_pm: number,
	timeEntries: TaskEntry[],
	equipment: Array<EquipmentEntry>,
	comment: string,
	gen_mode: string,
}

type EquipmentEntry = {
	[key: string]: number | string | undefined
	id: number | undefined,
	equipment_id: number | undefined,
	quantity: number | undefined,
	start_time: number | undefined,
	end_time: number | undefined,
	fuel: number | undefined,
	comment: string
}

const defaultEquipmentEntry: EquipmentEntry = {
	id: undefined,
	equipment_id: undefined,
	quantity: undefined,
	start_time: undefined,
	end_time: undefined,
	fuel: undefined,
	comment: ''
}

type TaskEntry = {
	[key: string]: number | string
	id: number,
	task_id: number,
	myoctans_task_id: number,
	BT: string,
	work_order_time: number,
	traveling_time: number,
	time: number,
	pay_time: number
}

type Props = {
	path: string
	report_id?: number
	disabled?: boolean
}

const DailyTasks: FC<Props> = (p) => {
	const { t } = useTranslation('daily_tasks')
	const queryClient = useQueryClient()
	const [state, actions] = useContext(globalContext)
	const filledDailyReport = useFilledDailyReport(p.report_id)
	const [employee, setEmployee] = useState<Api.Employee>()
	const [dailyReport, setDailyReport] = useState<DailyTaskForm>({
		id: undefined,
		project_id: undefined,
		created_by: state?.user?.id,
		blasting_number: '',
		date: DateTime.now(),
		break_time_am: '00:00',
		break_duration_am: 0,
		lunch_time: '12:00',
		lunch_duration: 0,
		break_time_pm: '15:00',
		break_duration_pm: 0,
		timeEntries: [],
		equipment: [],
		comment: '',
		gen_mode: 'simplified',
	})


	const prefetchTasks = async () => {
		await queryClient.prefetchQuery({
			queryKey: ['tasks'],
			queryFn: getTasks
		})
	}

	useEffect(() => {
		prefetchTasks()
	}, [])

	const { setBlob: setEmployeeSignature, append: appendEmployeeSignature } = useDrawing(
		'employee_signature',
		'employee_signature.png'
	)
	const { setBlob: setSupervisorSignature, append: appendSupervisorSignature } = useDrawing(
		'supervisor_signature',
		'supervisor_signature.png'
	)
	const type = 'daily_tasks'

	const { handleSubmit, submitDisabled } = useSubmit({
		async process(fd: FormData) {
			appendEmployeeSignature(fd)
			appendSupervisorSignature(fd)

			if (fd.get('comment')?.toString().trim().length === 0) fd.delete('comment')
		},
		submit: postDailyReport,
		async onSuccess(res) {
			actions.toastInfo(t('toasts:form_submitted'));
			setTimeout(() => {
				history.pushState({}, '', '/reports');
				location.href = '/reports';
			}, 1000);
		}
	})

	useEffect(() => {
		if (state?.user?.id) {
			getEmployeeByMyoctansId(state?.user?.id).then((employee: Api.Employee) => {
				setEmployee(employee)
			})
		}
	}, [])

	const handleProjectMatch = (project: object | undefined) => {
		const projectMatch = project as Api.Project
		const timeEntries = projectMatch?.tasks ? projectMatch.tasks.filter(task => !task.is_archived).map(task => {
			return {
				id: task.id,
				task_id: task.id,
				myoctans_task_id: task.myoctans_task_id,
				BT: task.BT,
				work_order_time: 0,
				traveling_time: 0,
				time: 0,
				pay_time: 0
			}
		}) : []
		setDailyReport({ ...dailyReport, project_id: projectMatch?.id, timeEntries: timeEntries })
	}

	const handleDateInChange = (e: Event) => {
		const input = e.target as HTMLInputElement
		const date = DateTime.fromISO(input.value)
		setDailyReport({ ...dailyReport, date: date })
	}

	const handleTaskInput = (e: Event) => {
		const input = e.target as HTMLInputElement
		const task_id = parseInt(input.name.split('[')[1].split(']')[0])
		const field = input.name.split('[')[2].split(']')[0]

		const updatedTimeEntries = dailyReport?.timeEntries.map(entry => {
			if (entry.task_id === task_id) {
				return { ...entry, [field]: parseFloat(input.value) };
			}
			return entry;
		});
		setDailyReport({ ...dailyReport, timeEntries: updatedTimeEntries });
	}

	const calculateTimes = () => {
		const timeIn = dailyReport?.date?.toFormat('HH:mm')
		const groupedTimeEntries = dailyReport?.timeEntries.reduce((acc: { [key: string]: number }, entry) => {
			const category = entry.BT;
			if (!acc[category]) {
				acc[category] = 0;
			}
			acc[category] += entry.work_order_time;
			return acc;
		}, {});

		const formattedGroupedTimeEntries = Object.entries(groupedTimeEntries).reduce((acc: { [key: string]: string }, [category, time]) => {
			acc[category] = hoursToTime(time);
			return acc;
		}, {});

		const taskTime = dailyReport?.timeEntries.reduce((acc, entry) => acc + entry.work_order_time, 0)
		const breakTime = (dailyReport?.break_duration_am + dailyReport?.lunch_duration + dailyReport?.break_duration_pm) / 60
		const workTotal = hoursToTime(taskTime + breakTime)
		const timeOut = dailyReport?.date?.hour + (dailyReport?.date?.minute / 60) + (taskTime + breakTime)
		return { taskTime: hoursToTime(taskTime), breakTime: hoursToTime(breakTime), workTotal, groupedTimeEntries: formattedGroupedTimeEntries, timeIn, timeOut: hoursToTime(timeOut) }
	}

	const { taskTime, breakTime, workTotal, groupedTimeEntries, timeIn, timeOut } = calculateTimes()

	return (
		<>
			<h1>{t('title')}</h1>
			<form onSubmit={handleSubmit}>
				<fieldset className='dummy' disabled={p.disabled}>
					<input type="hidden" name="id" value={dailyReport?.id} />
					<input type="hidden" name="gen_mode" value={'simplified'} />
					<input type="hidden" name="created_by" value={employee?.user?.id} />
					<input type="hidden" name="date" value={dailyReport?.date?.toISODate()} />
					<ProjectWithTaskSelect
						required
						name='project_id'
						defaultValue={filledDailyReport ? (dailyReport?.project_id as any) as number : undefined}
						label={t('project')}
						onMatch={handleProjectMatch}
					/>
					<Input
						className='span6'
						required
						max={DateTime.now().toFormat(INPUT_DATETIME_FMT)}
						value={dailyReport?.date?.toFormat(INPUT_DATETIME_FMT)}
						type='datetime-local'
						label={t('date_in')}
						onChange={handleDateInChange}
					/>
					<Input
						className='span6'
						type='text'
						name='blasting_number'
						label={t('blasting_number')}
					/>
					<fieldset>
						<legend>{t('time_entries')}</legend>
						{dailyReport?.timeEntries ? Object.entries(groupBy(dailyReport.timeEntries, 'BT')).map(([category, tasks]) => (
							<fieldset>
								<legend>{category}</legend>
								{tasks.map(task => (
									<>
										<input type="hidden" name={`time_entries[${task.id}][time]`} value={task.work_order_time} />
										<input type="hidden" name={`time_entries[${task.id}][pay_time]`} value={task.work_order_time} />
										<input type="hidden" name={`time_entries[${task.id}][task_id]`} value={task.id} />
										<input type="hidden" name={`time_entries[${task.id}][employee_id]`} value={employee?.id} />
										<input type="hidden" name={`time_entries[${task.id}][time_in]`} value={dailyReport.date.toFormat('HH:mm')} />
										<input type="hidden" name={`time_entries[${task.id}][myoctans_type]`} value={'daily_tasks'} />
										<TaskSelect
											required
											name={`time_entries[${task.id}][myoctans_task_id]`}
											selectedId={task.myoctans_task_id as number}
											label={t('task')}
											readOnly={true}
											className={'span2'}
										/>

										<Input
											label={t('hours')}
											className='span2'
											name={`time_entries[${task.id}][work_order_time]`}
											value={task.work_order_time}
											onBlur={handleTaskInput}
											decimal
										/>
										<Input
											label={t('traveling')}
											className='span2'
											name={`time_entries[${task.id}][traveling_time]`}
											value={task.traveling_time}
											onBlur={handleTaskInput}
											decimal
										/>
									</>
								))}
							</fieldset>
						)) :
							<p class="text-center">{t('select_a_project')}</p>
						}
					</fieldset>

					<fieldset>
						<legend>{t('times')}</legend>
						<TimePicker
							label={t('time_in')}
							value={timeIn}
							className='span6 flex flex-col'
						/>
						<TimePicker
							label={t('break_time_am')}
							name='break_time_am'
							value={dailyReport?.break_time_am?.toString()}
							className='span3 flex flex-col'
							onChange={(value) => setDailyReport({ ...dailyReport, break_time_am: value as string })}
						/>
						<Input
							className='span3'
							units='minutes'
							name='break_duration_am'
							label={t('break_duration')}
							integer
							value={dailyReport?.break_duration_am}
							onChange={(event) => setDailyReport({ ...dailyReport, break_duration_am: parseInt((event.target as HTMLInputElement)?.value) })}
						/>
						<TimePicker
							label={t('lunch_time')}
							name="lunch_time"
							value={dailyReport?.lunch_time?.toString()}
							onChange={(value) => setDailyReport({ ...dailyReport, lunch_time: value as string })}
							className='span3 flex flex-col'
						/>
						<Input
							className='span3'
							units='minutes'
							name='lunch_duration'
							label={t('lunch_duration')}
							value={dailyReport?.lunch_duration?.toString()}
							onChange={(event) => setDailyReport({ ...dailyReport, lunch_duration: parseInt((event.target as HTMLInputElement)?.value) })}
						/>
						<TimePicker
							label={t('break_time_pm')}
							name='break_time_pm'
							value={dailyReport?.break_time_pm?.toString()}
							onChange={(value) => setDailyReport({ ...dailyReport, break_time_pm: value as string })}
							className='span3 flex flex-col'
						/>
						<Input
							className='span3'
							units='minutes'
							name='break_duration_pm'
							label={t('break_duration')}
							value={dailyReport?.break_duration_pm?.toString()}
							onChange={(event) => setDailyReport({ ...dailyReport, break_duration_pm: parseInt((event.target as HTMLInputElement)?.value) })}
						/>
						<TimePicker
							label={t('time_out')}
							value={timeOut}
							className='span6 flex flex-col'
						/>
					</fieldset>

					<fieldset>
						<legend>{t('summary')}</legend>
						{
							groupedTimeEntries ? Object.entries(groupedTimeEntries).map(([category, time]) => (
								<Input
									className='span6'
									label={category}
									type={'text'}
									disabled
									value={time.toString()}
								/>
							)) : ''
						}
						<Input
							className='span6'
							label={t('task_time')}
							type={'text'}
							disabled
							value={taskTime.toString()}
						/>
						<Input
							className='span6'
							label={t('breaks')}
							type={'text'}
							disabled
							value={breakTime.toString()}
						/>
						<Input
							className='span6'
							label={t('work_total')}
							type={'text'}
							disabled
							value={workTotal.toString()}
						/>
					</fieldset>

					<fieldset>
						<legend>{t('equipments')}</legend>
						<div class='flex flex-col gap-y-8'>
							{dailyReport.equipment.map((equipment, i) =>
								<fieldset key={i}>
									<input type="hidden" name={`equipment[${i}][duration]`} value={(equipment.end_time ?? 0) - (equipment.start_time ?? 0)} />
									<EquipmentSelect
										className='span4'
										required
										name={`equipment[${i}][equipment_id]`}
										// defaultValue={r?.form?.other_materials[i].id}
										label={t('name')}
										filterkey={'forms'}
									/>
									<Input
										className='span2'
										required
										integer
										value={equipment.quantity}
										name={`equipment[${i}][quantity]`}
										onChange={(event) => {
											const updatedEquipment = [...dailyReport.equipment];
											updatedEquipment[i].quantity = parseInt((event.target as HTMLInputElement)?.value);
											setDailyReport({ ...dailyReport, equipment: updatedEquipment });
										}}
										label={t('quantity')}
									/>
									<Input
										className='span2'
										integer
										value={equipment.start_time}
										name={`equipment[${i}][start_time]`}
										onChange={(event) => {
											const updatedEquipment = [...dailyReport.equipment];
											updatedEquipment[i].start_time = parseInt((event.target as HTMLInputElement)?.value);
											setDailyReport({ ...dailyReport, equipment: updatedEquipment });
										}}
										label={t('start_time')}
									/>
									<Input
										className='span2'
										integer
										value={equipment.end_time}
										name={`equipment[${i}][end_time]`}
										onChange={(event) => {
											const updatedEquipment = [...dailyReport.equipment];
											updatedEquipment[i].end_time = parseInt((event.target as HTMLInputElement)?.value);
											setDailyReport({ ...dailyReport, equipment: updatedEquipment });
										}}
										label={t('end_time')}
									/>
									<Input
										className='span2'
										integer
										value={equipment.fuel}
										name={`equipment[${i}][fuel]`}
										onChange={(event) => {
											const updatedEquipment = [...dailyReport.equipment];
											updatedEquipment[i].fuel = parseInt((event.target as HTMLInputElement)?.value);
											setDailyReport({ ...dailyReport, equipment: updatedEquipment });
										}}
										label={t('fuel')}
									/>
									<Input
										className='span6'
										type='text'
										value={equipment.comment}
										name={`equipment[${i}][comment]`}
										onChange={(event) => {
											const updatedEquipment = [...dailyReport.equipment];
											updatedEquipment[i].comment = (event.target as HTMLInputElement)?.value;
											setDailyReport({ ...dailyReport, equipment: updatedEquipment });
										}}
										label={t('comments')}
									/>

								</fieldset>
							)}
							<div class='flex justify-end gap-x-2'>
								{dailyReport.equipment.length === 0 ? '' :
									<Btn danger onClick={() => setDailyReport({ ...dailyReport, equipment: dailyReport.equipment.slice(0, -1) })}>
										{t('forms:remove')}
									</Btn>
								}
								<Btn onClick={() => setDailyReport({ ...dailyReport, equipment: [...dailyReport.equipment, JSON.parse(JSON.stringify(defaultEquipmentEntry))] })}>
									{t('forms:add')}
								</Btn>

							</div>
						</div>
					</fieldset>
					<br />
					<Comments name='comment' label={t('comments')} />
					<Drawing label={t('employee_signature')} setBlob={setEmployeeSignature} />
					<Drawing label={t('supervisor_signature')} setBlob={setSupervisorSignature} />
					<Btn disabled={submitDisabled} submit>
						{t('forms:submit')}
					</Btn>
				</fieldset>
			</form>
		</>


	)
}
export default DailyTasks
