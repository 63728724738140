import { useContext, useEffect, useRef, useState } from 'preact/hooks'
import { useTranslation } from 'react-i18next'
import globalContext from '@/context'
import { DrillSelect, EquipmentSelect } from '@/components/forms/select'
import Input from '@/components/forms/Input'

type Props = {
	d?: any[]
    defaultValue?: DrillEquipment
    defaultCollapsed?: boolean
}

type EquipmentUsage = {
    equipment_id: number | undefined
    start_time: number
    end_time: number
}

export type DrillEquipment = {
    compressor: EquipmentUsage
    drill: EquipmentUsage
}

const DrillEquipmentSelect: FC<Props> = ({ d, ...p }) => {
	const { t } = useTranslation('driller')
    const [, actions] = useContext(globalContext);

    const [equipment, setEquipment] = useState<DrillEquipment>({
        compressor: {
            equipment_id: undefined,
            start_time: 0,
            end_time: 0
        },
        drill: {
            equipment_id: undefined,
            start_time: 0,
            end_time: 0
        }
    })

    useEffect(() => {
        if (p.defaultValue) {
            setEquipment(p.defaultValue)
        }
    }, [p.defaultValue])


    const drillDuration = (equipment.drill?.end_time ?? 0) - (equipment.drill?.start_time ?? 0)
    const compressorDuration = (equipment.compressor?.end_time ?? 0) - (equipment.compressor.start_time ?? 0)

	return (
            <fieldset>
                <legend>{t('equipments')}</legend>
                <fieldset>
                    <legend>{t('drill')}</legend>
                    <DrillSelect
                        className='span6'
                        type='text'
                        value={equipment.drill.equipment_id}
                        onChange={(e) => setEquipment({...equipment, drill: { ...equipment.drill, equipment_id: e.target.value }})}
                        name='equipments[0][equipment_id]'
                        label={t('drill_number')}
                    />
                    <Input
                        className='span2'
                        type='number'
                        value={equipment.drill.start_time}
                        onChange={(e) => setEquipment({...equipment, drill: { ...equipment.drill, start_time: e.target.value }})}
                        name='equipments[0][start_time]'
                        label={t('start_time')}
                    />
                    <Input
                        className='span2'
                        type='number'
                        value={equipment.drill.end_time}
                        onChange={(e) => setEquipment({...equipment, drill: { ...equipment.drill, end_time: e.target.value }})}
                        name='equipments[0][end_time]'
                        label={t('end_time')}
                    />
                    <Input
                        readonly
                        className='span2'
                        type='number'
                        value={drillDuration}
                        name='equipments[0][duration]'
                        label={t('total_hours')}
                    />
                </fieldset>
                <fieldset>
                    <legend>{t('compressor')}</legend>
                    <EquipmentSelect
                        className='span6'
                        type='text'
                        value={equipment.compressor.equipment_id}
                        onChange={(e) => setEquipment({...equipment, compressor: { ...equipment.compressor, id: e.target.value }})}
                        name='equipments[1][equipment_id]'
                        label={t('compressor_number')}
                    />
                    <Input
                        className='span2'
                        type='number'
                        value={equipment.compressor.start_time}
                        onChange={(e) => setEquipment({...equipment, compressor: { ...equipment.compressor, start_time: e.target.value }})}
                        name='equipments[1][start_time]'
                        label={t('start_time')}
                    />
                    <Input
                        className='span2'
                        type='number'
                        value={equipment.compressor.end_time}
                        onChange={(e) => setEquipment({...equipment, compressor: { ...equipment.compressor, end_time: e.target.value }})}
                        name='equipments[1][end_time]'
                        label={t('end_time')}
                    />
                    <Input
                        readonly
                        className='span2'
                        type='number'
                        value={compressorDuration}
                        name='equipments[1][duration]'
                        label={t('total_hours')}
                    />
                </fieldset>
            </fieldset>
	)
}

export default DrillEquipmentSelect
