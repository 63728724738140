import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

type Props = {
    name: string
    translation: string
}

const FileInputPreview: FC<Props> = (p) => {
    const { t } = useTranslation(p.translation)
    const [preview, setPreview] = useState<string | null>(null);

    const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const file = e.target.files?.[0];
        if (file) {
            const reader = new FileReader();
            reader.onloadend = () => {
                setPreview(reader.result as string);
            };
            reader.readAsDataURL(file);
        } else {
            setPreview(null);
        }
    };

    return (
        <div>
            <label>{t('picture')}</label>
            <input type="file" name={p.name} accept="image/*" onChange={handleFileChange} />
            {preview && (
                <img src={preview} alt="Preview" style={{ height: '100px', width: '100px' }} />
            )}
        </div>
    );
};

export default FileInputPreview;