import { route } from 'preact-router'
import { Icon } from '@iconify/react'
import receiptIcon from '@iconify/icons-bxs/receipt'
import closeSmall from '@iconify/icons-ci/close-small'
import tickSmallOutline from '@iconify/icons-teenyicons/tick-small-outline'
import classnames from 'classnames'

type Props = {
	receipt: any
	index: number
}

const Receipt: FC<Props> = ({ receipt, ...p }) => {
	const handleItemClick = (id: string) => {
		route(`/purchase/receipts/approve/${id}`, true)
	}
	return (
		<>
			<tr
				onClick={() => handleItemClick(receipt.id)}
				class={classnames(
					'items-center rounded-md  p-10 px-4 py-2 text-lg shadow-lg hover:cursor-pointer hover:bg-white',
					receipt.isRead ? 'bg-white' : 'bg-gray-50'
				)}
			>
				<td class='w-1/12 p-2'>
					<Icon icon={receiptIcon} width='20' height='20' color='#14b8a6' inline={true} />
				</td>
				<td class='w-5/12 p-2 text-center text-sm'>{receipt.number}</td>
				<td class='w-1/4  p-2 text-center text-sm'>{receipt.price}</td>
				<td class='w-1/4  p-2 text-center text-sm'>
					{receipt.status === 1 ? (
						<Icon
							icon={tickSmallOutline}
							color='green'
							width='25'
							height='25'
							style={{ margin: 'auto' }}
							inline={true}
						/>
					) : (
						<Icon
							icon={closeSmall}
							color='red'
							width='25'
							height='25'
							style={{ margin: 'auto' }}
							inline={true}
						/>
					)}
				</td>
			</tr>
		</>
	)
}
export default Receipt
