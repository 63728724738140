import Input from '@/components/forms/Input'
import { fmtFloat } from '@/helpers'
import { range } from 'lodash-es'
import { StateUpdater, useEffect, useRef, useState } from 'preact/hooks'
import { useTranslation } from 'react-i18next'

type Props = {
	d?: number[][]
	callback?: StateUpdater<{
		totalFeet: number;
		numOfHoles: number;
		avgDepth: number;
		cols: number;
		rows: number;
	}>
	name?: string
	index? : number
	m?: number[]
	withUnitSelect?: boolean
}

const DrillMatrix: FC<Props> = ({ d, ...p }) => {
	const { t } = useTranslation('driller')
	const [cols, setCols] = useState(d?.at(0)?.length ?? 8)
	const [rows, setRows] = useState(d?.length ?? 8)
	const [totalFeet, setTotalFeet] = useState(0)
	const [numOfHoles, setNumOfHoles] = useState(0)
	const [avgDepth, setAvgDepth] = useState(0)
	const tbodyRef = useRef<HTMLTableSectionElement>(null)

	useEffect(() => {
		handleInput()
	}, [rows, cols])

	function computeMetrics() {
		const cells = Array.from(tbodyRef.current?.querySelectorAll('input') ?? [])
			.map(input => Number.parseFloat(input.value))
			.filter(c => c)
		const totalFeet = cells.reduce((a, b) => a + b, 0)
		const numOfHoles = cells.length
		const avgDepth = totalFeet / numOfHoles

		return { totalFeet, numOfHoles, avgDepth }
	}

	const handleInput: any = () => {
		const { totalFeet, numOfHoles, avgDepth } = computeMetrics()
		setTotalFeet(totalFeet)
		setNumOfHoles(numOfHoles)
		setAvgDepth(avgDepth)
		if (p.callback) p.callback({
			totalFeet: totalFeet,
			numOfHoles: numOfHoles,
			avgDepth: avgDepth,
			cols: cols,
			rows: rows
		})
	}

	const handleResize = (set: StateUpdater<number>) => {
		return (e: any) => {
			const input: HTMLInputElement = e.target
			const v = Number.parseInt(input.value)
			if (v > 0 && v < Number.parseInt(input.max)) set(v)
		}
	}

	return (
		<>
			<div class='label mt-12 -mb-12'>{t('drill_matrix')}</div>
			<div class='label mt-2 flex justify-end gap-4'>
			{
				p.withUnitSelect ? 
				<label>{t('units')}
					<select name={p.name ? `${p.name}[matrix_units]` : 'form[matrix_units]'} >
						<option value='m'>{t('meters')}</option>
						<option value='f'>{t('feet')}</option>
					</select>
				</label> : 
				''
			}
				<Input
					label={t('rows')}
					integer
					min={8}
					max={26}
					value={rows}
					onBlur={handleResize(setRows)}
				/>
				<Input
					label={t('cols')}
					integer
					min={8}
					max={50}
					value={cols}
					onBlur={handleResize(setCols)}
				/>
			</div>
			<div class='overflow-auto'>
				<table class='h-64 w-full table-fixed'>
					<col class='w-4' />
					{range(0, cols).map(() => (
						<col class='w-16' />
					))}
					<thead>
						<tr>
							<th />
							{range(0, cols).map(x => (
								<th class='label'>{x + 1}</th>
							))}
						</tr>
					</thead>
					<tbody ref={tbodyRef}>
						{range(0, rows).map(y => (
							<tr>
								<th scope='row' class='label'>
									{String.fromCharCode(y + 65)}
								</th>
								{range(0, cols).map(x => (
									<td>
										<input
											onInput={handleInput}
											class='h-6 rounded-none border-none shadow-none'
											type='number'
											inputMode='decimal'
											min={0}
											step='any'
											key={`${y}-${x}`}
											name={p.name ? `${p.name}[matrix][${y}][${x}]` : `form[matrix][${y}][${x}]`}
											defaultValue={d?.at(y)?.at(x)?.toString()}
										/>
									</td>
								))}
							</tr>
						))}
					</tbody>
				</table>
			</div>
			<table class='info-table table-fixed'>
				<thead>
					<tr>
						<th>{t('total_length')}</th>
						<th>{t('num_of_holes')}</th>
						<th>{t('avg_depth')}</th>
					</tr>
				</thead>
				<tbody>
					<tr>
						<td>{fmtFloat(totalFeet)}</td>
						<td>{numOfHoles}</td>
						<td>{fmtFloat(avgDepth)}</td>
					</tr>
				</tbody>
			</table>
			<input type='hidden' name={p.name ? `${p.name}[total_feet]` : 'form[total_feet]'} value={totalFeet} />
			<input type='hidden' name={p.name ? `${p.name}[num_of_holes]` : 'form[num_of_holes]'}value={numOfHoles} />
			<input type='hidden' name={p.name ? `${p.name}[avg_depth]` : 'form[avg_depth]'} value={avgDepth} />
		</>
	)
}
export default DrillMatrix
