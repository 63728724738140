import { useEffect, useState } from 'preact/hooks'
import { useTranslation } from 'react-i18next'
import DynamicFieldset from './DynamicFieldset'
import Input from './Input'
import { MaterialSelect } from '@/components/forms/FuzzySelect'

type Props = {
	materials: Api.Material[] | undefined
    translation: string
    idName?: string
}

const MaterialList: FC<Props> = (p) => {
	const { t } = useTranslation(p.translation)
	const [ materials, setMaterials ] = useState<Api.Material[]>([])

    useEffect(() => {
        if (p.materials) {
            setMaterials(p.materials)
        }
    }, [p.materials])

	return (
		
        <DynamicFieldset
            data={materials}
            label={t('materials')}
            make={i => (
                <fieldset key={i}>
                    <MaterialSelect
                        className='span3'
                        required
                        name={p.idName ? `materials[${i}][${p.idName}]` : `materials[${i}][id]`}
                        label={t('name')}
                        filterkey={'daily_report'}
                        defaultValue={materials[i]?.id?.toString()}
                    />
                    <Input
                        className='span3'
                        required
                        integer
                        name={`materials[${i}][quantity]`}
                        label={t('quantity')}
                        defaultValue={materials[i]?.pivot.quantity?.toString()}
                    />
                </fieldset>
            )}
        />
		
	)

}
export default MaterialList
