import i18n from '@/i18n'
import { QueryClient } from '@tanstack/react-query'
import { without } from 'lodash-es'
import { createContext } from 'preact'
import { route } from 'preact-router'
import { useState } from 'preact/hooks'
import { useTranslation } from 'react-i18next'

const globalContext = createContext<ReturnType<typeof useGlobals>>(undefined!)
export default globalContext

export function useGlobals(queryClient?: QueryClient): [Readonly<typeof state>, typeof actions] {
	const { t } = useTranslation('toasts')
	const [messages, _setMessages] = useState<Message[]>([])
	const [notifications, setNotifications] = useState<Api.Notification[]>([])
	const [apiToken, setApiToken] = useLocalStorage<string | null>('api_token', null)
	const [user, setUser] = useLocalStorage<Api.User | null>('user', null)
	const [lang, _setLang] = useLocalStorage<string>('lang', navigator.language.slice(0, 2) || 'en')
	const [receipts, setReceipts] = useState<Array<Record<string, any>>>([])
	const [authStatus, setAuthStatus] = useState<boolean>(false)
	const state = { messages, apiToken, lang, user, notifications, receipts, authStatus }

	const actions = {
		toastInfo(str: string) {
			const msg: Message = { str, type: 'info' }
			_setMessages([...messages, msg])
			setTimeout(() => {
				this.clearMessage(msg)
			}, 3000)
		},
		toastError(str: string) {
			if (str?.trim().length > 0) _setMessages([...messages, { str, type: 'error' }])
		},
		clearMessage(msg: Message) {
			_setMessages(without(messages, msg))
		},
		clearMessages() {
			_setMessages([])
		},
		logIn(token: string, user: Api.User) {
			setApiToken(token)
			setUser(user)
			route('/', true)
			setAuthStatus(true)
		},
		logOut() {
			setApiToken(null)
			setUser(null)
			localStorage.clear()
			route('/login', true)
			setAuthStatus(false)
			queryClient?.invalidateQueries()
		},
		setLang(lang: 'en' | 'fr') {
			_setLang(lang)
			i18n.changeLanguage(lang)
		},
		setReceiptData(receipts: Array<Record<string, any>>) {
			setReceipts(receipts)
		},
		setNotifications(notifications: Array<Api.Notification>) {
			setNotifications(notifications)
		}
	}

	return [state, actions]
}

function useLocalStorage<T>(key: string, initial: T): [T, (v: T) => void] {
	const [storedValue, _setVal] = useState(() => {
		const val = localStorage.getItem(key)
		if (val === null) return initial
		if (val.startsWith('{') || val.startsWith('[')) return JSON.parse(val)
		return val
	})

	const setVal = (val: T) => {
		_setVal(val)
		if (val === null) localStorage.removeItem(key)
		else if (typeof val === 'object') localStorage.setItem(key, JSON.stringify(val))
		else localStorage.setItem(key, String(val))
	}

	return [storedValue, setVal]
}
