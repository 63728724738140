import Btn from '@/components/forms/Btn'
import { range } from 'lodash-es'
import { useEffect, useState } from 'preact/hooks'
import { useTranslation } from 'react-i18next'
import CollapsibleFieldset from '../CollapsibleFieldset'

type Props = {
	make: (i: number, d?: Record<string, any>) => JSX.Element
	label: string
	data?: Record<string, any>[]
	defaultLength?: number
	disabled?: boolean
	collapsible?: boolean
	defaultCollapsed?: boolean
}
const DynamicFieldset: FC<Props> = p => {
	const [items, setItems] = useState(p.data?.length ?? 0)
	const { t } = useTranslation('forms')

	useEffect(() => {
		setItems(p.data?.length ?? 0)
	}, [p.data])

	if (p.collapsible) {
		return (
			<CollapsibleFieldset title={p.label} defaultCollapsed={p.defaultCollapsed}>
				<div class='flex flex-col gap-y-8'>{range(0, items).map(i => p.make(i, p.data?.at(i)))}</div>
				<div class='flex justify-end gap-x-2'>
					{items >= 1 && (
						<Btn danger onClick={() => setItems(items - 1)}>
							{t('forms:remove')}
						</Btn>
					)}
					<Btn onClick={() => {setItems(items + 1)}}>{t('forms:add')}</Btn>
				</div>
				{p.children}
			</CollapsibleFieldset>
		)
	}

	return (
		<fieldset disabled={p.disabled ?? false}>
			<legend>{p.label}</legend>
			<div class='flex flex-col gap-y-8'>{range(0, items).map(i => p.make(i, p.data?.at(i)))}</div>
			<div class='flex justify-end gap-x-2'>
				{items >= 1 && (
					<Btn danger onClick={() => setItems(items - 1)}>
						{t('forms:remove')}
					</Btn>
				)}
				<Btn onClick={() => {setItems(items + 1)}}>{t('forms:add')}</Btn>
			</div>
			{p.children}
		</fieldset>
	)
}
export default DynamicFieldset
