import { useEffect, useState } from 'preact/hooks'
import { useTranslation } from 'react-i18next'
import DynamicFieldset from './DynamicFieldset'
import Input from './Input'

type Props = {
	others: Api.Others[] | undefined
    translation: string
}

const OthersList: FC<Props> = (p) => {
	const { t } = useTranslation(p.translation)
	const [ others, setOthers ] = useState<Api.Others[]>([])

    useEffect(() => {
        if (p.others) {
            setOthers(p.others)
        }
    }, [p.others])

	return (
		
        <DynamicFieldset
            data={others}
            label={t('others')}
            make={i => (
                <fieldset key={i}>
                    <Input
                        required
                        name={`others[${i}][name]`}
                        label={t('name')}
                        defaultValue={others[i]?.name}
                    />
                    <Input
                        className='span3'
                        required
                        integer
                        name={`others[${i}][quantity]`}
                        label={t('quantity')}
                        defaultValue={others[i]?.quantity.toString()}
                    />
                    <Input
                        className='span3'
                        required
                        units='dollars'
                        name={`others[${i}][price]`}
                        label={t('price')}
                        defaultValue={others[i]?.price.toString()}
                    />
                </fieldset>
            )}
        />
		
	)

}
export default OthersList
