import classNames from 'classnames'
import type { FunctionalComponent as FC } from 'preact'
import type Icon from '~icons/bi/calendar-check'

export const NavGrid: FC = p => (
	<div class='grid grid-cols-2 justify-around gap-6'>{p.children}</div>
)

export const NavItem: FC<{
	label: string
	href: string
	icon?: typeof Icon
	class?: string
}> = p => (
	<a
		href={p.href}
		class={classNames(
			'flex h-32 flex-col flex-wrap items-center justify-center rounded-xl bg-white shadow-sm',
			p.class
		)}
	>
		{p.icon && <p.icon class='text-teal-600' width='2em' height='2em' />}
		<div style="max-width:90%; text-align: center;" class='text-md mt-2 font-medium text-gray-700'>{p.label}</div>
	</a>
)
