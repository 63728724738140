import { useEffect, useState } from 'preact/hooks'
import { getFilteredData } from '@/api/mydata'
import Btn from '@/components/forms/Btn'
import Input from '@/components/forms/Input'
import { API_DATETIME_FMT, INPUT_DATETIME_FMT, INPUT_DATETIME_FMT_ENTRY } from '@/constants'
import { DateTime } from 'luxon'
import { useRef } from 'preact/hooks'
import { useTranslation } from 'react-i18next'
import type TimeEntry from '@/pages/reports/daily'

const TimeCard = ({ setData }: { setData: (dt: Api.TimeEntry[]) => void }) => {
	const { t } = useTranslation('time_entries')
	const now = DateTime.now()
	const oneWeekAgo = DateTime.now().minus({ days: 7 })
	const now_format = fmtDate(now)
	const oneWeekAgo_format = fmtDate(oneWeekAgo)
	const dateIn = fmtDate(oneWeekAgo)
	const dateOut = fmtDate(now)
	const dateForm = useRef<any>()

	const handleFilterClick = async () => {
		const date = dateForm.current
		await getFilteredData(date['start_date'].value, date['end_date'].value).then(item =>
			setData(item as Api.TimeEntry[])
		)
	}

	useEffect(() => {
		handleFilterClick()
	}, [])
	return (
		<>
			<div>
				<form ref={dateForm}>
					<Input
						required
						max={now_format}
						defaultValue={dateIn ?? oneWeekAgo_format}
						type='date'
						name='start_date'
						label={t('start_date')}
					/>
					<Input
						required
						max={now_format}
						defaultValue={dateOut ?? now_format}
						type='date'
						name='end_date'
						label={t('end_date')}
					/>
				</form>
				<Btn class='mt-4 w-full' onClick={() => handleFilterClick()}>
					{t('filter')}
				</Btn>
			</div>
		</>
	)
}
export default TimeCard

function fmtDate(placeholder: DateTime, dateStr?: string): string {
	const date = dateStr ? DateTime.fromFormat(dateStr, API_DATETIME_FMT) : placeholder
	return date.toFormat(INPUT_DATETIME_FMT_ENTRY)
}
