import { useEffect, useRef, useState } from 'preact/hooks'
import { useTranslation } from 'react-i18next'

const MAX_LEN = 1000

type Props = JSX.HTMLAttributes<HTMLTextAreaElement> & { name: string; label?: string }
const Comments: FC<Props> = ({ label, class: className, ...p }) => {
	const { t } = useTranslation('forms')
	const [len, setLen] = useState(0)
	const ref = useRef<HTMLTextAreaElement>(null)

	const handleChange: any = () => {
		setLen(ref.current?.textLength ?? 0)
	}

	useEffect(() => {
		setLen(ref.current?.textLength ?? 0)
	})

	return (
		<label class={className}>
			{label ?? t('comments')}
			<textarea onChange={handleChange} ref={ref} maxLength={MAX_LEN} class='h-32' {...p} />
			<div class='mt-1 text-right text-xs font-normal text-gray-500'>
				{len ?? 0} / {MAX_LEN}
			</div>
		</label>
	)
}
export default Comments
