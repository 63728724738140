import ControlledInput from '@/components/forms/ControlledInput'
import Input from '@/components/forms/Input'
import VariableUnitsInput from '@/components/forms/VariableUnitsInput'
import { fmtFloat } from '@/helpers'
import { useEffect, useRef, useState } from 'preact/hooks'
import { useTranslation } from 'react-i18next'

type Props = { form?: Record<string, any> }

const ExplosivesFieldsets: FC<Props> = p => {
	const { t } = useTranslation('shooting_log')
	const [burden, setBurden] = useState<number>(p.form?.burden ? parseFloat(p.form?.burden) : 0)
	const [spacing, setSpacing] = useState<number>(p.form?.spacing ? parseFloat(p.form?.spacing) : 0)
	const [collarHeight, setCollarHeight] = useState<number>(p.form?.collar_height ? parseFloat(p.form?.collar_height) : 0)
	const [natureOfStorage, setNatureOfStorage] = useState<number>(p.form?.nature_of_storage ? parseFloat(p.form?.nature_of_storage) : 0)
	const [heightOfDeathGround, setHeightOfDeathGround] = useState<number>(p.form?.height_of_death_ground ? parseFloat(p.form?.height_of_death_ground) : 0)
	const [nbrHoles, setNbrHoles] = useState<number>(p.form?.nbr_holes ? parseFloat(p.form?.burden) : 0)
	

	const explosivesQtyRef = useRef<HTMLInputElement>(null)
	const pumpedEmulsionRef = useRef<HTMLInputElement>(null)
	const [avgDepth, setAvgDepth] = useState(p.form?.avg_depth_per_row ?? 0)
	const [rocVolM3, setRocVolM3] = useState(0)
	const [rocVolM2, setRocVolM2] = useState(0)
	const [loadingFactor, setLoadingFactor] = useState(0)

	function computeMetrics() {
		const [explosivesQty, pumpedEmulsion] = [
			explosivesQtyRef,
			pumpedEmulsionRef,
		].map(ref => Number.parseFloat(ref.current?.value ?? '') || 0)

		setRocVolM3(burden * spacing * (avgDepth - heightOfDeathGround) * nbrHoles)
		setRocVolM2(burden * spacing * nbrHoles)
		setLoadingFactor(rocVolM3 ? (pumpedEmulsion + explosivesQty) / rocVolM3 : NaN)
	}

	useEffect(() => {
		computeMetrics()
	}, [avgDepth, nbrHoles, burden, spacing, heightOfDeathGround])

	return (
		<>
			<fieldset>
				<legend>{t('drilling_data')}</legend>
				<ControlledInput
					className='span6'
					callback={setNbrHoles}
					name='form[nbr_holes]'
					defaultValue={p.form?.nbr_holes}
					label={t('nbr_holes')}
					integer
				/>
				<VariableUnitsInput
					className='span3'
					name='form[drilling_diameter]'
					defaultValue={p.form?.drilling_diameter}
					label={t('drilling_diameter')}
					choices='inchesOrMillimeters'
				/>
				<VariableUnitsInput
					className='span3'
					setComputedVal={setBurden}
					name='form[burden]'
					defaultValue={p.form?.burden}
					label={t('burden')}
					choices='feetOrMeters'
				/>
				<VariableUnitsInput
					className='span3'
					setComputedVal={setSpacing}
					name='form[spacing]'
					defaultValue={p.form?.spacing}
					label={t('spacing')}
					choices='feetOrMeters'
				/>
				<VariableUnitsInput
					choices='feetOrMeters'
					setComputedVal={setAvgDepth}
					name='form[avg_depth_per_row]'
					defaultValue={p.form?.avg_depth_per_row}
					label={t('avg_depth_per_row')}
				/>
				<VariableUnitsInput
					choices='feetOrMeters'
					setComputedVal={setCollarHeight}
					name='form[collar_height]'
					defaultValue={p.form?.collar_height}
					label={t('collar_height')}
				/>
				<VariableUnitsInput
					choices='feetOrMeters'
					setComputedVal={setHeightOfDeathGround}
					name='form[height_of_death_ground]'
					defaultValue={p.form?.height_of_death_ground}
					label={t('height_of_death_ground')}
				/>
				<VariableUnitsInput
					choices='inchesOrMillimeters'
					setComputedVal={setNatureOfStorage}
					name='form[nature_of_storage]'
					defaultValue={p.form?.nature_of_storage}
					label={t('nature_of_storage')}
				/>
			</fieldset>
			<fieldset>
				<legend>{t('distance_from_structures')}</legend>
				<Input
					className='span2'
					name='form[building]'
					defaultValue={p.form?.building}
					label={t('building')}
					units='meters'
				/>
				<Input
					className='span2'
					name='form[bridge]'
					defaultValue={p.form?.bridge}
					label={t('bridge')}
					units='meters'
				/>
				<Input
					className='span2'
					name='form[road]'
					defaultValue={p.form?.road}
					label={t('road')}
					units='meters'
				/>
				<Input
					className='span3'
					name='form[electric_line]'
					defaultValue={p.form?.electric_line}
					label={t('electric_line')}
					units='meters'
				/>
				<Input
					className='span3'
					name='form[underground_structure]'
					defaultValue={p.form?.underground_structure}
					label={t('underground_structure')}
					units='meters'
				/>
			</fieldset>
			<fieldset>
				<legend>{t('explosives')}</legend>
				<Input
					className='span3'
					onInput={computeMetrics}
					ref={explosivesQtyRef}
					name='form[explosives_qty]'
					defaultValue={p.form?.explosives_qty}
					label={t('explosives_qty')}
					units='kg'
				/>
				<Input
					className='span3'
					name='form[detonators_nbr]'
					defaultValue={p.form?.detonators_nbr}
					label={t('detonators_nbr')}
					decimal
				/>
				<Input
					className='span3'
					onInput={computeMetrics}
					ref={pumpedEmulsionRef}
					name='form[pumped_emulsion_qty]'
					defaultValue={p.form?.pumped_emulsion_qty}
					label={t('pumped_emulsion_qty')}
					decimal
				/>
				<Input
					className='span3'
					name='form[tonnage]'
					defaultValue={p.form?.tonnage}
					label={t('tonnage')}
					decimal
				/>
				<input type='hidden' name='form[roc_vol_m3]' value={rocVolM3} />
				<input type='hidden' name='form[roc_vol_m2]' value={rocVolM2} />
				<input type='hidden' name='form[loading_factor]' value={loadingFactor} />
			</fieldset>
			<table class='info-table mb-6 table-fixed'>
				<thead>
					<tr>
						<th>{t('roc_vol_m3')}</th>
						<th>{t('roc_vol_m2')}</th>
						<th>{t('loading_factor')}</th>
					</tr>
				</thead>
				<tbody>
					<tr>
						<td>{fmtFloat(rocVolM3)}</td>
						<td>{fmtFloat(rocVolM2)}</td>
						<td>{fmtFloat(loadingFactor)}</td>
					</tr>
				</tbody>
			</table>
		</>
	)
}
export default ExplosivesFieldsets
