import React from 'react';
import { useTranslation } from 'react-i18next';

type TimePickerProps = {
    value: string
    name?: string
    label: string
    className?: string
    onChange?: (value: string) => void
} & JSX.HTMLAttributes<HTMLInputElement>

const TimePicker: React.FC<TimePickerProps> = ({ value, name, label, className, onChange, ...options }) => {
    const { t } = useTranslation('forms')

    return (
        <>
            <div class={className}>
                <label>{label}</label>
                {onChange ? (
                    <input
                        type="time"
                        name={name}
                        value={value}
                        onChange={(e) => onChange(e.target.value)}
                    />
                ) : (
                    <input
                        type="time"
                        name={name}
                        value={value}
                        readOnly
                    />
                )}
            </div>
        </>
    );
}



export default TimePicker;