import { StateUpdater, useEffect, useState } from 'preact/hooks'
import { useTranslation } from 'react-i18next'

type Props = {
	label?: string
	units?: 'hours' | 'minutes' | 'meters' | 'km' | 'inches' | 'kg' | 'dollars' | 'celsius'
	integer?: boolean
	decimal?: boolean
    className?: string
    callback?: StateUpdater<any>
} & JSX.HTMLAttributes<HTMLInputElement>

const ControlledInput: FC<Props> = ({ label, units, integer, decimal, className, ...p }) => {
		const { t } = useTranslation('forms')
        const [ value, setValue ] = useState(p.value)

        const onChange = (event: Event) => {
            var target: HTMLInputElement = event.target as HTMLInputElement
            setValue(target.value)
            if (p.callback) p.callback(target.value)
        }

		useEffect(() => {
			setValue(p.value)
		}, [p.value]) 

		return (
			<label class={className}>
				{label}
				<span className='lowercase text-gray-400'>{units && ` (${t(units)})`}</span>
				{p.required && <span class='text-red-600'>&nbsp;*</span>}
				<input
					type='text'
					{...(integer && INT)}
					{...(decimal && DECIMAL)}
					{...p}
                    value={value}
                    onChange={onChange}
				/>
			</label>
		)
}

export default ControlledInput

const INT = { type: 'number', inputMode: 'numeric', min: 0 }
const DECIMAL = { type: 'number', inputMode: 'decimal', min: 0, step: 'any' }
const UNITS = {
	minutes: { ...INT },
	hours: { ...DECIMAL },
	meters: { ...DECIMAL },
	km: { ...DECIMAL },
	inches: { ...DECIMAL },
	kg: { ...DECIMAL },
	dollars: { ...DECIMAL },
	celsius: {},
}
