import { NavGrid, NavItem } from '@/components/NavGrid'
import { useTranslation } from 'react-i18next'
import CalendarCheckIcon from '~icons/bi/calendar-check'
import ReceiptIcon from '~icons/bi/receipt-cutoff'
import MydataIcon from '~icons/gala/data'
import BiJournalMedical from '~icons/bi/journal-medical'

const Home: FC<{ path: string }> = () => {
	const { t } = useTranslation('routes')

	return (
		<NavGrid>
			<NavItem label={t('reports')} icon={CalendarCheckIcon} href='/reports' />
			<NavItem label={t('purchase')} icon={ReceiptIcon} href='/purchase' />
			<NavItem label={t('my_data')} icon={MydataIcon} href='/mydata' />
			<NavItem label={t('planned_reports')} icon={CalendarCheckIcon} href='/planned_report' />
			<NavItem label={t('health_security')} icon={BiJournalMedical} href='/health_security' />
			{/* <NavItem label={t('tasks')} icon={ChecksIcon} href='/tasks' /> */}
		</NavGrid>
	)
}
export default Home
