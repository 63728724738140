import classNames from 'classnames'
import { useRouter } from 'preact-router'
import { useTranslation } from 'react-i18next'

type Props = JSX.HTMLAttributes<HTMLButtonElement> & {
	submit?: boolean
	danger?: boolean
	ghost?: boolean
	lg?: boolean
}

const Btn: FC<Props> = ({ class: className, children, submit, danger, ghost, lg, ...p }) => {
	const { t } = useTranslation('forms')
	return (
		<button
			type={submit ? 'submit' : 'button'}
			class={classNames(
				'button flex items-center justify-center gap-x-4',
				{
					'primary mx-auto my-12 px-8 py-3': submit,
					'bg-teal-500 text-white shadow-md disabled:bg-gray-400 disabled:shadow-none':
						!danger && !ghost,
					'text-teal-500 active:text-teal-700 disabled:text-gray-300': ghost,
					'text-red-600 active:text-red-800 disabled:text-gray-300': danger,
					'px-4 py-2 text-sm': !lg,
					'px-8 py-3': lg,
				},
				className
			)}
			{...p}
		>
			{children}
		</button>
	)
}
export default Btn
