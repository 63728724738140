import { NavGrid, NavItem } from '@/components/NavGrid'
import { useTranslation } from 'react-i18next'
import BiStopwatch from '~icons/bi/stopwatch'

const HealthSecurity: FC<{ path: string }> = () => {
	const { t } = useTranslation('routes')

	return (
		<NavGrid>
			<NavItem label={t('take5')} href='/health_security/take5' icon={BiStopwatch} />
		</NavGrid>
	)
}
export default HealthSecurity
