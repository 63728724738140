import { postPackingSlip } from '@/api/purchase'
import Comments from '@/components/forms/Comments'
import Drawing, { useDrawing } from '@/components/forms/Drawing'
import Form from '@/components/forms/Form'
import DynamicFieldset from '@/components/forms/DynamicFieldset'
import { EmployeeSelect, MaterialSelect, EquipmentSelect, LocationSelect, PurchaseOrderSelect } from '@/components/forms/FuzzySelect'
import Input from '@/components/forms/Input'
import { useTranslation } from 'react-i18next'
import globalContext from '@/context'
import { useContext } from 'preact/hooks'

const Reception: FC<{ path: string }> = () => {
	const { t } = useTranslation('reception');
	const [, actions] = useContext(globalContext);
	const { setBlob: setSignature, append: appendSignature } = useDrawing(
		'signature',
		'signature.png'
	)
	const submitArgs = {
		submit: postPackingSlip,
		async process(fd: FormData) {
			appendSignature(fd)
		},
		async onSuccess() {
			actions.toastInfo(t('toasts:form_submitted'))
			history.back()
		},
	}

	return (
		<Form submitArgs={submitArgs}>
			<Input name='number' required label={t('packing_slip_no')} />
			<PurchaseOrderSelect name='purchase_order_id' required label={t('purchase_order')} />
			<LocationSelect name='location_id' required label={t('current_location')} />
            <DynamicFieldset
					label={t('equipments')}
					make={i => (
						<fieldset key={i}>
							<EquipmentSelect
                                className='span3'
								required
								name={`equipment[${i}][id]`}
								label={t('name')}
								filterkey={'daily_report'}
							/>
							<Input
								className='span3'
								required
								integer
								name={`equipment[${i}][quantity]`}
								label={t('quantity')}
							/>
						</fieldset>
					)}
				/>
				<DynamicFieldset
					label={t('materials')}
					make={i => (
						<fieldset key={i}>
							<MaterialSelect
								className='span3'
								required
								name={`materials[${i}][id]`}
								label={t('name')}
								filterkey={'daily_report'}
							/>
							<Input
								className='span3'
								required
								integer
								name={`materials[${i}][quantity]`}
								label={t('quantity')}
							/>
						</fieldset>
					)}
				/>
				<DynamicFieldset
					label={t('others')}
					make={i => (
						<fieldset key={i}>
							<Input
                                className='span3'
                                required
                                name={`others[${i}][name]`}
                                label={t('name')} />
							<Input
								className='span3'
								required
								integer
								name={`others[${i}][quantity]`}
								label={t('quantity')}
							/>
						</fieldset>
					)}
				/>
			<Input name='picture' type='file' accept='image/*' label={t('forms:picture')} />
			<EmployeeSelect name='supervisor_id' required label={t('supervisor')} />
			<Input name='date_received' type='date' label={t('packing_slip_date')} />
			<Comments name='note' />
			<Drawing label={t('forms:signature')} setBlob={setSignature} />
		</Form>
	)
}
export default Reception
