import { login } from '@/api/auth'
import Btn from '@/components/forms/Btn'
import Input from '@/components/forms/Input'
import Img from '@/components/Img'
import globalContext from '@/context'
import { useSubmit } from '@/hooks'
import { useContext, useEffect } from 'preact/hooks'
import { useTranslation } from 'react-i18next'

const LogIn: FC<{ path: string }> = () => {
	const { t } = useTranslation('login')
	const [, actions] = useContext(globalContext)
	const { handleSubmit: onSubmit, submitDisabled } = useSubmit<Api.LogIn>({
		submit: login,
		onSuccess: async res => {
			actions.logIn(res.api_token, res.user)
		},
	})

	useEffect(() => {
		actions.logOut()
	}, [])

	return (
		<>
			<Img src='/logo/login-light.png' class='mx-auto mb-8 h-64' alt='logo-light' />
			<form onSubmit={onSubmit} class='place-items-center'>
				<Input
					type='text'
					inputMode='numeric'
					autocomplete='username'
					autofocus
					name='email'
					label={t('pin')}
				/>
				<Input
					type='password'
					inputMode='numeric'
					autocomplete='current-password'
					name='password'
					label={t('password')}
				/>
				<Btn disabled={submitDisabled} submit>
					{t('login')}
				</Btn>
			</form>
		</>
	)
}
export default LogIn
