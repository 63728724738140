import { StateUpdater, useEffect, useState } from 'preact/hooks'
import { useTranslation } from 'react-i18next'

const UNITS = {
	feetOrMeters: { meters: 1, feet: 0.3048 },
	inchesOrMillimeters: { millimeters: 1, inches: 0.0393701 },
	litersOrGallons: { liters: 1, gallons: 4.54609 },
}

type Props = JSX.HTMLAttributes<HTMLInputElement> & {
	label: string
	choices: keyof typeof UNITS
	setComputedVal?: StateUpdater<number>
	set?: (val: number) => void
}
const VariableUnitsInput: FC<Props> = ({ label, choices, setComputedVal, set, name, ...p }) => {
	const { t } = useTranslation('forms')
	const units = UNITS[choices]
	const [unit, setUnit] = useState<string>(Object.keys(units)[0])
	const [val, setVal] = useState<number>(Number.parseFloat(p.defaultValue as string) || 0)
	const computeVal = () => val * units[unit as never]
	const computeValue = (value: number) => value * units[unit as never]

	const handleValChange: any = (e: any) => {
		// @ts-ignore
		if (p.onChange) p.onChange(e)
		const num = Number.parseFloat(e.target.value)
		if (!num) return
		setVal(num)
		if (setComputedVal) {
			setComputedVal(computeVal)
		}
	
		if (set) {
			set(computeValue(num))
		}
	}

	const handleUnitChange: any = (e: any) => {
		setUnit(e.target.value)
		if (setComputedVal) {
			setComputedVal(computeVal)
		}
	
		if (set) {
			set(computeValue(val))
		}
	}

	return (
		<label>
			{label}
			<div class='flex gap-x-4'>
				<input
					type='number'
					inputMode='decimal'
					value={val || undefined}
					step='any'
					min='0'
					onInput={handleValChange}
					{...p}
				/>
				<select class='w-32' onInput={handleUnitChange}>
					{Object.keys(units).map(u => (
						<option value={u} checked={unit === u}>
							{t(u)}
						</option>
					))}
				</select>
			</div>
			<input type='hidden' name={name} value={computeVal()} />
		</label>
	)
}
export default VariableUnitsInput
