import { useEffect, useState } from 'preact/hooks'
import { useTranslation } from 'react-i18next'
import DynamicFieldset from './DynamicFieldset'
import Input from './Input'
import { EquipmentSelect } from '@/components/forms/FuzzySelect'

type Props = {
	equipments: Api.Equipment[] | undefined
    translation: string
    idName?: string
}

const EquipmentList: FC<Props> = (p) => {
	const { t } = useTranslation(p.translation)
	const [ equipments, setEquipments ] = useState<Api.Equipment[]>([])

    useEffect(() => {
        if (p.equipments) {
            setEquipments(p.equipments)
        }
    }, [p.equipments])

	return (
		
        <DynamicFieldset
            data={equipments}
            label={t('equipments')}
            make={i => (
                <fieldset key={i}>
                    <EquipmentSelect
                        required
                        name={p.idName ? `equipment[${i}][${p.idName}]` : `equipment[${i}][id]`}
                        label={t('name')}
                        filterkey={'daily_report'}
                        defaultValue={equipments[i]?.id.toString()}
                    />
                    <Input
                        className='span3'
                        required
                        integer
                        name={`equipment[${i}][quantity]`}
                        label={t('quantity')}
                        defaultValue={equipments[i]?.pivot?.quantity.toString()}
                    />
                    <Input
                        className='span3'
                        required
                        units='hours'
                        name={`equipment[${i}][duration]`}
                        label={t('duration')}
                        defaultValue={equipments[i]?.pivot?.duration.toString()}
                    />
                </fieldset>
            )}
        />
		
	)

}
export default EquipmentList
