import { SummaryData } from "./DrillingSummary"
import type { HoleDescription } from "./HoleDescriptionForm"
import type { DrillEquipment } from "./DrillEquipmentSelect"
import type { MatrixData } from "./Matrix"

export const validateHoleData = (holeData: HoleDescription) => {
    const errors: Record<string, string> = {}
    if (holeData.hole_number === '') {
        errors.hole_number = 'Hole number is required'
    }
    if (holeData.depth_planned === 0) {
        errors.depth_planned = 'Depth planned is required'
    }
    if (holeData.depth_drilled === 0) {
        errors.depth_drilled = 'Depth drilled is required'
    }
    if (holeData.depth_measured === 0) {
        errors.depth_measured = 'Depth measured is required'
    }
    if (holeData.angle === 0) {
        errors.angle = 'Angle is required'
    }
    if (holeData.clean === 0) {
        errors.clean = 'Clean is required'
    }
    if (holeData.broken_steel === 0) {
        errors.broken_steel = 'Broken steel is required'
    }
    if (holeData.broken_rocks === 0) {
        errors.broken_rocks = 'Broken rocks is required'
    }
    if (holeData.trepine_number === 0) {
        errors.trepine_number = 'Trepine number is required'
    }
    return errors
}

export const delaysChoices =  (t: (key: string) => string): {[index: string]:any} => ({
    redrilling: t('redrilling'),
    cleaning_blowing: t('cleaning_blowing'),
    displacement: t('displacement'),
    repair_maintenance: t('repair_maintenance'),
    meeting_lunch_break: t('meeting_lunch_break'),
    access: t('access'),
    dust_limit: t('dust_limit'),
    sound_limit: t('sound_limit'),
    drilling: t('drilling'),
    traveling: t('traveling'),
    standby: t('standby'),
    transport: t('transport'),
    downtime: t('downtime'),
    safety_meeting: t('safety_meeting'),
    preshift_inspection: t('preshift_inspection'),
    special_work: t('special_work'),
    mechanical_time: t('mechanical_time'),
    wait_time: t('wait_time')
});

export const yesNoChoices =  (t: (key: string) => string): {[index: string]:any } => ({
    yes: t('yes'),
    no: t('no')
});

export const initHolesArray = (cols: number, rows: number): HoleDescription[] => {
    const holesArray: HoleDescription[] = []
    for (let i = 0; i < cols * rows; i++) {
        holesArray.push({
            hole_number: String.fromCharCode(Math.ceil((i + 1) / cols) + 64) + ((i % rows) + 1),
            is_hole_number_changed: false,
            depth_planned: 0,
            matrix_input: 0,
            depth_drilled: 0,
            depth_measured: 0,
            water: '',
            angle: 0,
            clean: 0,
            clean_reforated: '',
            remote: '',
            broken_steel: 0,
            broken_rocks: 0,
            trepine_number: 0,
            comment: '',
            trephine_number: '',
            hole_description: '',
            diameter_value: 0,
            diameter_units: '',
            time_casing: 0,
            casing_meters: 0
        })
    }
    return holesArray
}

export const calculateSummary = (matrixData: MatrixData): SummaryData => {
    const totalFeet = matrixData.holeDescriptions?.reduce((a, b) => a + b.depth_drilled, 0)
    const numOfHoles = matrixData.holeDescriptions?.length
    const avgDepth = parseFloat((totalFeet / numOfHoles).toFixed(2))

    const m = totalFeet
    const m2 = parseFloat(((matrixData.spacing * (matrixData.cols - 1)) * (matrixData.burden * (matrixData.rows - 1))).toFixed(2))
    const m3 = parseFloat(((matrixData.spacing * (matrixData.cols - 1)) * (matrixData.burden * (matrixData.rows - 1)) * avgDepth).toFixed(2))
    const drilled = matrixData.holeDescriptions?.reduce((a, b) => {
        return a + ((b.remote == 'Yes' || b.remote == 'Oui') ? 0 : b.depth_drilled)
    }, 0)
    const drilledRemote = totalFeet - drilled
    const recovery = matrixData.holeDescriptions?.reduce((a, b) => {
        return a + (b.clean_reforated == 'R' ? b.depth_drilled : 0)
    }, 0)
    const cleaning = matrixData.holeDescriptions?.reduce((a, b) => {
        return a + (b.clean_reforated == 'N' ? b.depth_drilled : 0)
    }, 0)
    const blowing = 0
    
    return {
        m: parseFloat(m.toFixed(2)),
        m2: parseFloat(m2.toFixed(2)),
        m3: parseFloat(m3.toFixed(2)),
        drilled: parseFloat(drilled.toFixed(2)),
        drilledRemote: parseFloat(drilledRemote.toFixed(2)),
        recovery: parseFloat(recovery.toFixed(2)),
        cleaning: parseFloat(cleaning.toFixed(2)),
        blowing: parseFloat(blowing.toFixed(2)),
    }
}

export const makeMatrixData = (report?: Api.Report): MatrixData | undefined => {
    if (!report) {
        return undefined
    }
    const form = report.form
    const cols = form.matrix ? form.matrix.length : 0
    const rows = form.matrix ? form.matrix[0]?.length : 0
    const holeDescriptions: HoleDescription[] = []
    form.mine?.forEach((hole: any) => {
        holeDescriptions.push({
            hole_number: hole.hole_number,
            is_hole_number_changed: false,
            depth_planned: parseFloat(hole.depth_planned),
            matrix_input: hole.matrix_input,
            depth_drilled: parseFloat(hole.depth_drilled),
            depth_measured: parseFloat(hole.depth_measured),
            water: hole.water,
            angle: parseFloat(hole.angle),
            clean: parseFloat(hole.clean),
            remote: hole.remote,
            broken_steel: parseFloat(hole.broken_steel),
            broken_rocks: parseFloat(hole.broken_rocks),
            time_casing: parseFloat(hole.time_casing),
            casing_meters: parseFloat(hole.casing_meters),
            comment: hole.comment,
            trephine_number: hole.trephine_number,
            clean_reforated: hole.clean_reforated,
            hole_description: hole.hole_description,
            diameter_value: parseFloat(hole.diameter_value),
            diameter_units: hole.diameter
        })
    })

    const matrixData: MatrixData = {
        cols: cols,
        rows: rows,
        burden: form.burden,
        spacing: form.spacing,
        holeDescriptions: holeDescriptions.length == 0 ? initHolesArray(cols, rows) : holeDescriptions,
    }

    return matrixData
}

export const makeDrillEquipment = (report?: Api.Report): DrillEquipment | undefined => {
    if (!report) {
        return undefined
    }
    const drill = report.equipment?.at(0)
    const compressor = report.equipment?.at(1)
    const drillEquipment: DrillEquipment = {
        drill: {
            equipment_id: drill?.id,
            start_time: drill?.pivot?.start_time ?? 0,
            end_time: drill?.pivot?.end_time ?? 0
        },
        compressor: {
            equipment_id: compressor?.id,
            start_time: compressor?.pivot?.start_time ?? 0,
            end_time: compressor?.pivot?.end_time ?? 0
        },
    }
    return drillEquipment
}
