import { NavGrid, NavItem } from '@/components/NavGrid'
import { useTranslation } from 'react-i18next'
import ClockIcon from '~icons/bi/clock'
import ListCheckIcon from '~icons/bi/list-check'
import { env } from '@/constants'

const ReceiptsIndex: FC<{ path: string }> = () => {
	const { t } = useTranslation('routes')

	return (
		<NavGrid>
			<NavItem label={t('send_receipt')} href='/purchase/receipts/send' icon={ClockIcon} />
			<NavItem
				label={t('approve_receipt')}
				href='/purchase/receipts/approve'
				icon={ListCheckIcon}
			/>
		</NavGrid>
	)
}
export default ReceiptsIndex
