import { InvalidArgumentsError } from '@/api/helpers'
import { getReports, getTimeEntriesLastMonth } from '@/api/reports'
import { getTasks } from '@/api/resources'
import globalContext, { useGlobals } from '@/context'
import { dbg, getBlob } from '@/helpers'
import { useContext, useEffect, useRef, useState } from 'preact/hooks'
import { useQuery } from '@tanstack/react-query'
import type ReactSignatureCanvas from 'react-signature-canvas'

type Args<T> = {
	process?: (fd: FormData) => Promise<void>
	submit: (fd: FormData) => Promise<T>
	onSuccess?: (res: T) => Promise<void>
	onError?: (err: Error) => boolean
}

type Result = {
	handleSubmit: JSX.GenericEventHandler<HTMLFormElement>
	submitDisabled: boolean
}

export function useSubmit<T>(args: Args<T>): Result {
	const [, actions] = useContext(globalContext)
	const [submitDisabled, setSubmitDisabled] = useState(false)

	return {
		handleSubmit: async e => {
			e.preventDefault()
			const form = e.target as HTMLFormElement
			if (!form.reportValidity()) return
			const fd = new FormData(form)
			try {
				setSubmitDisabled(true)
				if (args.process) await args.process(fd)
				var body = await args.submit(fd)
			} catch (err: any) {
				switch (err.constructor) {
					case InvalidArgumentsError:
						for (const msg of err.errors) actions.toastError(msg)
						break
					default:
						if (args.onError) args.onError(err)
						else throw err
				}
				// Enable re-submit on error
				setSubmitDisabled(false)
				return
			}

			actions.clearMessages()
			if (args.onSuccess) {
				await args.onSuccess(body)
			} else {
				scrollTo({ top: 0 })
				actions.toastInfo('Form submitted')
			}
		},
		submitDisabled,
	}
}

export function useCanvas(name = 'signature', filename = 'signature.png') {
	const canvasRef = useRef<ReactSignatureCanvas>(null)

	return {
		canvasRef,
		async process(fd: FormData) {
			if (!canvasRef.current?.isEmpty()) {
				const blob = await getBlob(canvasRef.current!.getTrimmedCanvas())
				fd.append(name, blob, filename)
			}
		},
	}
}

export function useFilledReport(report_id?: string): Api.Report | undefined {
	const { data: reports } = useReports()
	const id = report_id ? Number.parseInt(report_id) : undefined
	return reports?.find(r => r.id === id)
}

export function useFilledTimeEntry(timeEntryId?: number): Api.TimeEntry | undefined {
	const { data: timeEntries } = useQuery<Api.TimeEntry[]>({
		queryKey: ['time-entries'],
		queryFn: getTimeEntriesLastMonth
	})
	
	return timeEntries?.find(timeEntry => timeEntry.id == timeEntryId)
}

export function useFilledDailyReport(report_id?: number): Api.DailyReport | undefined {
	const { data: reports } = useReports()
	const id = report_id ? report_id : undefined
	return reports?.find(r => r.id === id) as Api.DailyReport
}

export function useReports() {
	return useQuery({
		queryKey: ['reports'],
		queryFn: getReports,
	})
}

export function useTasks() {
	return useQuery({
		queryKey: ['tasks'],
		queryFn: getTasks,
	})
}

export function useDbg(values: Record<string, any>) {
	useEffect(() => dbg(values), Object.values(values))
}

export function usePermission(groupname: string, formname: string) {
	const [s, ] = useGlobals()
	const permission = s.user?.permissions
	type permissionKey = keyof typeof permission
	
	if(permission?.hasOwnProperty(groupname as permissionKey)) {
		const group : any = permission?.[groupname as permissionKey]
		type groupKey = keyof typeof group
		if(group.hasOwnProperty(formname as groupKey)) {
			const permission_val:number = group[formname as groupKey]
			if(permission_val != 1) return false
		}
	}
	return true
}