import Checkbox from '@/components/forms/Checkbox'
import Comments from '@/components/forms/Comments'
import Input from '@/components/forms/Input'
import { BonusesSelect } from '@/components/forms/MultiSelect'
import globalContext from '@/context'
import { useFilledTimeEntry } from '@/hooks'
import type { FunctionComponent as FC } from 'preact'
import { useContext } from 'preact/hooks'
import { useTranslation } from 'react-i18next'
import DailyReport from '@/components/DailyReport'
import MaterialList from '@/components/forms/MaterialList'
import EquipmentList from '@/components/forms/EquipmentList'
import ToggleableDrillingMachine from '@/components/forms/ToggleableDrillingMachine'

type Props = {
	path: string
	report_id?: number
	disabled?: boolean
}

const DailyDriller: FC<Props> = (p) => {
	const { t } = useTranslation('daily_driller')
	const timeEntry = useFilledTimeEntry(p.report_id)
	const [, actions] = useContext(globalContext)
	const submitArgs = {
		async process(fd: FormData) {
			if (fd.get('comment')?.toString().trim().length === 0) fd.delete('comment')
		}
	}

	return (
		<>
			<DailyReport type={'daily_driller'} timeEntry={timeEntry} translation='daily_driller' submitArgs={submitArgs} disabled={p.disabled} withTraveling={true}>
				<Checkbox
					name='is_ccq'
					label={t('ccq')}
					defaultValue={timeEntry?.is_ccq ? 'on' : ''}
				/>
				<label>
					{t('bonuses')}
					<BonusesSelect
						name='bonuses[]'
						defaultValue={timeEntry?.bonuses}
					/>
				</label>
				<MaterialList
					materials={timeEntry?.materials}
					translation='daily_driller'
				/>
				<EquipmentList
					equipments={timeEntry?.equipment}
					translation='daily_driller'
				/>
				<ToggleableDrillingMachine
					drillingMachine={timeEntry?.ew_has_equipments}
					translation='daily_driller'
				/>

				<Input
					type='file'
					accept='image/*'
					multiple
					name='images[]'
					label={t('pictures')}
				/>
				<Comments
					name='comment'
					defaultValue={timeEntry?.comment}
				/>
			</DailyReport>
		</>
	)
}
export default DailyDriller
