import { NavGrid, NavItem } from '@/components/NavGrid'
import { useTranslation } from 'react-i18next'
import ClockIcon from '~icons/bi/clock'
import ListCheckIcon from '~icons/bi/list-check'
import { env } from '@/constants'

const Index: FC<{ path: string }> = () => {
	const { t } = useTranslation('routes')

	return (
		<NavGrid>
			<NavItem label={t('receipts')} href='/purchase/receipts' icon={ListCheckIcon} />
			{env.DEV && <NavItem label={t('orders')} href='/purchase/orders' icon={ClockIcon} />}
			<NavItem label={t('reception')} href='/purchase/reception' icon={ListCheckIcon} />
		</NavGrid>
	)
}
export default Index
