import { useTranslation } from "react-i18next"

const Loading: FC<Record<string, never>> = p => {
	const { t } = useTranslation('forms')

	return (
		<>
            <div style='display: flex; justify-content: center;'>
                <div className="loader"></div>
            </div>
		</>
	)
}
export default Loading