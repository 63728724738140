import { getBonuses } from '@/api/resources'
import Checkbox from '@/components/forms/Checkbox'
import globalContext from '@/context'
import classNames from 'classnames'
import { useContext, useEffect, useState } from 'preact/hooks'

type Props = JSX.HTMLAttributes<HTMLInputElement> & {
	prefix?: string
	choices: { label: string; name: string; value?: string }[]
	d?: Record<string, string>
}
const MultiSelect: FC<Props> = ({ prefix, choices, d, class: className, ...p }) => {
	return (
		<div class={classNames('mt-1 flex flex-col gap-y-1', className)}>
			{choices.map(c => (
				<Checkbox
					label={c.label}
					name={prefix ? `${prefix}[${c.name}]` : c.name}
					value={c.value}
					defaultValue={c.value}
					defaultChecked={c?.d?.find((val) => {return val.id == c?.value }) != undefined}
					class='text-sm font-normal normal-case text-gray-700'
					{...p}
				/>
			))}
		</div>
	)
}
export default MultiSelect

function makeMultiSelect<T extends Api.Resource>(
	getItems: () => Promise<T[]>
): FC<Omit<Props, 'choices'>> {
	return ({ children, ...p }) => {
		const [, actions] = useContext(globalContext)
		const [items, setItems] = useState<T[]>([])

		useEffect(() => {
			getItems()
				.then(setItems)
				.catch(err => {
					actions.toastError(err?.message)
				})
		}, [])

		return (
			<MultiSelect
				choices={items.map(it => ({ label: it.name, name: it.name, value: it.id.toString(), d: p.defaultValue }) )}
				{...p}
			/>
		)
	}
}

export const BonusesSelect = makeMultiSelect<Api.Bonus>(getBonuses)
