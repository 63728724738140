import { API_DATETIME_FMT, INPUT_DATETIME_FMT, MEASURE_UNITS } from '@/constants'
import { FetchStatus } from '@tanstack/react-query'
import { DateTime } from 'luxon'

export function getBlob(canvas: HTMLCanvasElement): Promise<Blob> {
	return new Promise((resolve, reject) => {
		canvas.toBlob(blob => {
			if (blob) resolve(blob)
			else reject()
		})
	})
}

export function fdProxy(fd: FormData): Record<string, any> {
	return new Proxy(fd, {
		get(fd, p) {
			return fd.get(p as string)
		},
		set(fd, p, val) {
			fd.set(p as string, val)
			return true
		},
		deleteProperty(fd, p) {
			fd.delete(p as string)
			return true
		},
	})
}

export function privateRoute(): boolean {
	const p = location.pathname
	return !p.startsWith('/login') && !p.startsWith('/signup')
}

export function guessDevice(): 'android' | undefined {
	const ua = navigator.userAgent.toLowerCase()
	if (ua.includes('android')) return 'android'
	return undefined
}

export function fmtFloat(n?: number | null): string {
	return Object.is(NaN, n) || n === undefined || n === null ? '-' : n.toFixed(2)
}

export function logged<T>(v: T): T {
	console.log(v)
	return v
}

export function dbg(values: Record<string, any>) {
	if (import.meta.env) console.log(values)
}

function fmtDate(placeholder: DateTime, dateStr?: string): string {
	const date = dateStr ? DateTime.fromFormat(dateStr, API_DATETIME_FMT) : placeholder
	return date.toFormat(INPUT_DATETIME_FMT)
}

export function computeMeasureUnit(value: number, choices: string, unit: string) {
	return value * MEASURE_UNITS[choices as never][unit as never]
}

export function isQueryLoading(status: string, fetchStatus: FetchStatus) {
	return status === 'pending' && fetchStatus === 'fetching'
}