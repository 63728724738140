import { api, auth } from '@/api/helpers'
import { route } from 'preact-router'

export async function login(body: FormData): Promise<Api.LogIn> {
	return auth.post('login', { body }).json()
}

export async function logout(): Promise<void> {
	try {
		await api.post('logout')
		localStorage.clear
	} finally {
		route('/login', true)
	}
}

export async function ping(): Promise<void> {
	await api.get('ping')
}
