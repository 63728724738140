import { useState } from 'preact/hooks'
import EntryTimeCard from './EntryTimeCard'
import { useTranslation } from 'react-i18next'
import { Icon } from '@iconify/react';
import hourglassBottom from '@iconify/icons-bi/hourglass-bottom';
import hourglassSplit from '@iconify/icons-bi/hourglass-split';
import hourglassTop from '@iconify/icons-bi/hourglass-top';

const TimeEntries: FC<ReportProps> = p => {
	const { t } = useTranslation('time_entries')
	const [data, setData] = useState<Api.TimeEntry[]>([])
	var day_stat_flag = false
	var week_stat_flag = false
	type Day_Stat = {
		reg_hrs: number,
		over_15_hrs: number,
		over_hrs: number,
		not_worked_reg_hrs: number,
		not_worked_over_15_hrs: number,
		not_worked_over_hrs: number,
		qte: number,
	}
	var day_stat: Day_Stat = {
		reg_hrs: 0,
		over_15_hrs: 0,
		over_hrs: 0,
		not_worked_reg_hrs: 0,
		not_worked_over_15_hrs: 0,
		not_worked_over_hrs: 0,
		qte: 0,
	}
	var week_stat: Day_Stat = {
		reg_hrs: 0,
		over_15_hrs: 0,
		over_hrs: 0,
		not_worked_reg_hrs: 0,
		not_worked_over_15_hrs: 0,
		not_worked_over_hrs: 0,
		qte: 0,
	}

	data.sort((a, b)=> new Date(a.date_in).getTime() - new Date(b.date_in).getTime() )

	return (
		<>
			<h1>{t('routes:time_entries')}</h1>
			<EntryTimeCard setData={setData} />
			<div class='mb-16 mt-8 ml-32 mr-32  flex items-center justify-between'>
				<div class='mt-2 text-center font-medium'>
					<p class='text-md'>{t('tr')}</p>
					<p class='mt-2'>{(data.reduce((prev, cur) => prev + (cur.pay_time ?? 0), 0) / 60).toFixed(1)}</p>
				</div>
				<div class='mt-2 text-center font-medium'>
					<p class='text-md'>{t('td1.5')}</p>
					<p class='mt-2'>
						{(data.reduce((prev, cur) => prev + (cur.pay_overtime_15 ?? 0), 0) / 60).toFixed(1)}
					</p>
				</div>
				<div class='mt-2 text-center font-medium'>
					<p class='text-md'>{t('td2')}</p>
					<p class='mt-2'>
						{(data.reduce((prev, cur) => prev + (cur.pay_overtime ?? 0), 0) / 60).toFixed(1)}
					</p>
				</div>
			</div>
			<div class='mb-16 mt-8 ml-32 mr-32  flex items-center justify-between'>
				<div class='mt-2 text-center font-medium'>
					<p class='text-md'>{t('tnt')}</p>
					<p class='mt-2'>{(data.reduce((prev, cur) => prev + (cur.time - (cur.pay_time ?? 0)), 0) / 60).toFixed(1)}</p>
				</div>
				<div class='mt-2 text-center font-medium'>
					<p class='text-md'>{t('tntd1.5')}</p>
					<p class='mt-2'>
						{(data.reduce((prev, cur) => prev + (cur.overtime_15 - (cur.pay_overtime_15 ?? 0)), 0) / 60).toFixed(1)}
					</p>
				</div>
				<div class='mt-2 text-center font-medium'>
					<p class='text-md'>{t('tntd2')}</p>
					<p class='mt-2'>
						{(data.reduce((prev, cur) => prev + (cur.overtime - (cur.pay_overtime ?? 0)), 0) / 60).toFixed(1)}
					</p>
				</div>
			</div>
			{data.length === 0 ? (
				<div class='text-center'>{t('no_time_entries')}</div>
			) : (
				<div class='mt-8 max-h-screen overflow-scroll'>
					<table class='w-[48rem] table-fixed rounded-md'>
						<thead class='items-center rounded-sm bg-teal-500 px-4 py-2 text-lg text-gray-100 shadow-md'>
							<tr>
								<th rowSpan={2} class='w-28 px-2 text-center text-xs'>{t('date')}</th>
								<th rowSpan={2} class='w-20 p-2 text-center text-xs'>{t('task')}</th>
								<th rowSpan={2} class='w-20 p-2 text-center text-xs'>{t('tr')}</th>
								<th rowSpan={2} class='w-20 p-2 text-center text-xs'>{t('tnt')}</th>
								<th rowSpan={2} class='w-20 p-2 text-center text-xs'>{t('td1.5')}</th>
								<th rowSpan={2} class='w-20 p-2 text-center text-xs'>{t('tntd1.5')}</th>
								<th rowSpan={2} class='w-20 p-2 text-center text-xs'>{t('td2')}</th>
								<th rowSpan={2} class='w-20 p-2 text-center text-xs'>{t('tntd2')}</th>
								<th colSpan={2} class='p-2 text-center text-xs'>{t('bonuses')}</th>
							</tr>
							<tr>
								<th class='w-20 px-2 text-center text-xs'>{t('name')}</th>
								<th class='w-20 px-2 text-center text-xs'>{t('qte')}</th>
							</tr>
						</thead>
						<tbody class='items-center rounded-md px-4 py-2 shadow-sm'>
							{data.map((item: Api.TimeEntry, id, arr) => {
								week_stat_flag = false
								var today = new Date(item.date_in)
								var todaydate = today.toLocaleDateString('en-CA')
								var overpaytime = item.pay_overtime ?? 0
								var overpaytime15 = item.pay_overtime_15 ?? 0
								var paytime = item.pay_time ?? 0
								if(arr[id-1]) {
									var prevelement = arr[id-1]
									var thedaybefore = new Date(prevelement.date_in)
									var prevdate = thedaybefore.toLocaleDateString('en-CA')

									if(prevdate != todaydate) {
										day_stat = {
											reg_hrs: 0,
											over_15_hrs: 0,
											over_hrs: 0,
											not_worked_reg_hrs: 0,
											not_worked_over_15_hrs: 0,
											not_worked_over_hrs: 0,
											qte: 0,
										}
										const availabledays = 6 - thedaybefore.getDay()
										const difference = today.getTime() - thedaybefore.getTime();
										const totalDays = Math.ceil(difference / (1000 * 3600 * 24));
										if(totalDays > availabledays) {
											week_stat = {
												reg_hrs: 0,
												over_15_hrs: 0,
												over_hrs: 0,
												not_worked_reg_hrs: 0,
												not_worked_over_15_hrs: 0,
												not_worked_over_hrs: 0,
												qte: 0,
											}
										}
									}
								}

								if(arr[id+1]) {
									var nextelement = arr[id+1]
									var thedayafter = new Date(nextelement.date_in)
									var nextdate = thedayafter.toLocaleDateString('en-CA')
									if(nextdate == todaydate) {
										day_stat_flag = false
									} else {
										day_stat_flag = true

										var availabledays = 6 - today.getDay()
										const difference = thedayafter.getTime() - today.getTime();
										const totalDays = Math.ceil(difference / (1000 * 3600 * 24));
										if(totalDays > availabledays) {
											week_stat_flag = true
										}
									}
								} else {
									day_stat_flag = true
									week_stat_flag = true
								}

								day_stat.reg_hrs += paytime
								day_stat.over_15_hrs += overpaytime15
								day_stat.over_hrs += overpaytime
								day_stat.not_worked_reg_hrs += item.time - (item.pay_time ?? 0)
								day_stat.not_worked_over_15_hrs += item.overtime_15 - (item.pay_overtime_15 ?? 0)
								day_stat.not_worked_over_hrs += item.overtime - (item.pay_overtime ?? 0)
								day_stat.qte += item.bonuses.length

								if(day_stat_flag) {

									week_stat.reg_hrs += day_stat.reg_hrs
									week_stat.over_15_hrs += day_stat.over_15_hrs
									week_stat.over_hrs += day_stat.over_hrs
									week_stat.not_worked_reg_hrs += day_stat.not_worked_reg_hrs
									week_stat.not_worked_over_15_hrs += day_stat.not_worked_over_15_hrs
									week_stat.not_worked_over_hrs += day_stat.not_worked_over_hrs
									week_stat.qte += day_stat.qte

								}

								return (
									<>
										<tr
											key={id}
											class='rounded-md border bg-gray-50 p-2 text-lg shadow-lg hover:cursor-pointer hover:bg-white'
										>
											<td class='p-2'>
												<div class='text-sm'>
													{ todaydate }
												</div>
												<div class='text-xs'>
													{new Date(item.date_in).toTimeString().slice(0, 5)} -{' '}
													{new Date(item.date_out).toTimeString().slice(0, 5)}
												</div>
											</td>
											<td class='p-2 text-xs text-center'>{ item.task.name }</td>
											<td class='px-2 text-right text-xs'>{(paytime / 60).toFixed(2)}</td>
											<td class='p-2 text-xs text-right'>{ (((item.time - (item.pay_time ?? 0))) / 60).toFixed(2) }</td>
											<td class='px-2 text-right text-xs'>{(overpaytime15 / 60).toFixed(2)}</td>
											<td class='p-2 text-xs text-right'>{ ((item.overtime_15 - (item.pay_overtime_15 ?? 0)) / 60).toFixed(2) }</td>
											<td class='px-2 text-right text-xs'>{(overpaytime / 60).toFixed(2)}</td>
											<td class='p-2 text-xs text-right'>{ ((item.overtime - (item.pay_overtime ?? 0)) / 60).toFixed(2) }</td>
											<td class='content-start px-2 text-xs text-teal-500 text-left'>{item.bonuses.map((elem) => {
												return (
													<><p>{`${elem.name}`}</p></>
												);
											})}</td>
											<td class='p-2 text-xs'>
												<div class='text-teal-500 text-right'>{item.bonuses.length}</div>
											</td>
										</tr>
										{day_stat_flag === true ? (
											<tr class='rounded-md border bg-blue-100 p-2 text-lg shadow-lg hover:cursor-pointer'>
												<td class='p-2 text-xs text-center' colSpan={2}>
													{ t('total') }
													{' '}
													{ todaydate }
												</td>
												<td class='p-2 text-xs text-right'>
													{ (day_stat.reg_hrs / 60).toFixed(2) }
												</td>
												<td class='p-2 text-xs text-right'>
													{ (day_stat.not_worked_reg_hrs / 60).toFixed(2) }
												</td>
												<td  class='p-2 text-xs text-right'>
													{ (day_stat.over_15_hrs / 60).toFixed(2) }
												</td>
												<td  class='p-2 text-xs text-right'>
													{ (day_stat.not_worked_over_15_hrs / 60).toFixed(2) }
												</td>
												<td class='p-2 text-xs text-right'>
													{ (day_stat.over_hrs / 60).toFixed(2) }
												</td>
												<td  class='p-2 text-xs text-right'>
													{ (day_stat.not_worked_over_hrs / 60).toFixed(2) }
												</td>
												<td></td>
												<td class='p-2 text-xs text-right'>
													{ day_stat.qte }
												</td>
											</tr>
										) : (
											<></>
										)}
										{week_stat_flag === true ? (
											<tr class='rounded-md border p-2 text-lg shadow-lg hover:cursor-pointer bg-teal-500 text-gray-100'>
												<td class='p-2 text-xs text-center' colSpan={2}>
													{ t('week_total') }
												</td>
												<td class='p-2 text-xs text-right'>
													{ (week_stat.reg_hrs / 60).toFixed(2) }
												</td>
												<td class='p-2 text-xs text-right'>
													{ (week_stat.not_worked_reg_hrs / 60).toFixed(2) }
												</td>
												<td class='p-2 text-xs text-right'>
													{ (week_stat.over_15_hrs / 60).toFixed(2) }
												</td>
												<td  class='p-2 text-xs text-right'>
													{ (week_stat.not_worked_over_15_hrs / 60).toFixed(2) }
												</td>
												<td class='p-2 text-xs text-right'>
													{ (week_stat.over_hrs / 60).toFixed(2) }
												</td>
												<td  class='p-2 text-xs text-right'>
													{ (week_stat.not_worked_over_hrs / 60).toFixed(2) }
												</td>
												<td class='p-2 text-xs text-right' colSpan={2}>
													{ week_stat.qte }
												</td>
											</tr>
										) : (
											<></>
										)}
									</>
								)
							})}
						</tbody>
					</table>
				</div>
			)}
		</>
	)
}
export default TimeEntries
