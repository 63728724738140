import { useState } from 'react'
import { getSingleReceipt, putNotification, putReceipt } from '@/api/purchase'
import Comments from '@/components/forms/Comments'
import Form from '@/components/forms/Form'
import Input from '@/components/forms/Input'
import Img from '@/components/Img'
import { useContext, useEffect } from 'preact/hooks'
import { useTranslation } from 'react-i18next'
import globalContext from '@/context'
import Btn from '@/components/forms/Btn'
import { Icon } from '@iconify/react'
import tickSmallOutline from '@iconify/icons-teenyicons/tick-small-outline'

import { route } from 'preact-router'
import { getNotifications, getProjects } from '@/api/resources'
import { EmployeeSelect } from '@/components/forms/FuzzySelect'

const ApproveSingleReceipt: FC<{ path: string; receipt_id: string }> = p => {
	const { t } = useTranslation('approve_receipt')
	const [s, actions] = useContext(globalContext)
	const [data, setData] = useState<Record<string, any>>({
		number: '',
		price: '',
		project_id: '',
		file_url: '',
		supervisor_id: '',
		note: '',
		signature_url: '',
	})
	const [projectName, setProjectName] = useState<string>()

	useEffect(() => {
		(async function () {
			if (p.receipt_id) {
				const unread = s.notifications.find(
					i => i.data.receipt_id === Number.parseInt(p.receipt_id) && i.read_at === null
				)
				if (unread) {
					await putNotification(unread.id)
					await getNotifications().then(items => {
						actions.setNotifications(items)
					})
				}
				await getSingleReceipt(p.receipt_id).then(res => {
					setData(res)
					getProjects().then(items => {
						const name = items.find(item => item.id == res.project_id)?.name
						setProjectName(name)
					})
				})
			}
		})()
	}, [p.receipt_id])
	const handleClick = async () => {
		await putReceipt(p.receipt_id, { is_approved: 1 })
		actions.toastInfo(t('approve_receipt:approve_success'))
		route('/purchase/receipts/approve', true)
	}
	const handleForwardAction = async () => {
		if (document.querySelector('[list="forward-supervisor-list"]')?.value !== '') {
			const supervisor_id = document.getElementsByName('forward-supervisor')[0]?.value
			await putReceipt(p.receipt_id, { supervisor_id: Number.parseInt(supervisor_id) })
			actions.toastInfo(t('approve_receipt:forward_success'))
			route('/purchase/receipts/approve', true)
		}
	}
	return (
		<>
			<h1>{t('routes:approve_receipt')}</h1>
			<Input
				name='number'
				label={t('receipt_number')}
				value={data.number}
				className='mb-2 bg-gray-100 hover:cursor-default focus:outline-0'
				readOnly
			/>
			<Input
				name='supervisor'
				label={t('send_receipt:supervisor')}
				value={s.user?.name}
				className='mb-2 bg-gray-100 hover:cursor-default focus:outline-0'
				readOnly
			/>
			<Input
				name='project'
				label={t('forms:project')}
				value={projectName}
				className='mb-2 bg-gray-100 hover:cursor-default focus:outline-0'
				readOnly
			/>
			<Input
				name='price'
				label={t('forms:price')}
				value={data.price}
				className='mb-2 bg-gray-100 hover:cursor-default focus:outline-0'
				readOnly
			/>
			<Img
				src={data.file_url}
				label={t('forms:picture')}
				alt=''
				style={{ width: '50%', margin: 'auto' }}
			/>
			<Comments
				name='note'
				value={data.note}
				className='mb-2 h-44 bg-gray-100 hover:cursor-default focus:outline-0'
				readOnly
			/>
			<Img src={data.signature_url} alt='' style={{ width: '50%', margin: 'auto' }} />

			{data.status === 0 ? (
				<>
					<div class='mt-5 mb-5 flex justify-center'>
						<Btn onClick={() => handleClick()}>{t('approve_receipt:approve')}</Btn>
					</div>

					<EmployeeSelect
						name='forward-supervisor'
						label={t('approve_receipt:forward_to_other_supervisor')}
					/>

					<div className='mt-5 flex justify-center'>
						<Btn onClick={() => handleForwardAction()}>{t('approve_receipt:forward')}</Btn>
					</div>
				</>
			) : (
				<div class='m-auto flex w-96 items-center justify-center'>
					<Icon
						icon={tickSmallOutline}
						color='green'
						width='25'
						height='25'
						style={{ margin: 'auto' }}
						inline={true}
					/>
					<div>
						{data.status == 1 ? t('approve_receipt:this_receipt_has_been_approved_on') : t('approve_receipt:this_receipt_has_been_refused_on')} {data.approved_date}
					</div>
				</div>
			)}
		</>
	)
}
export default ApproveSingleReceipt
