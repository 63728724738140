import DrillMatrix from '@/pages/reports/driller/Matrix'
import Report from '@/components/Report'
import Comments from '@/components/forms/Comments'
import Input from '@/components/forms/Input'
import { BorerSelect } from '@/components/forms/select'
import VariableUnitsInput from '@/components/forms/VariableUnitsInput'
import { useFilledReport } from '@/hooks'
import { useTranslation } from 'react-i18next'
import { DrillSelect } from '@/components/forms/FuzzySelect'
import Options from '@/components/forms/Options'

const DrillerReport: FC<ReportProps> = p => {
	const { t } = useTranslation('driller')
	const r = useFilledReport(p.report_id)

	return (
		<Report type='driller' report={r} disabled={p.disabled === 'disabled'}>
			<input type='hidden' name='version' defaultValue='2' />
			<label>
				{t('drill_type')}
				<select name='form[drill_type]'>
					<Options t={t} options={['stone_pit', 'construction']} d={r?.form.drill_type} />
				</select>
			</label>
			<DrillSelect
				name='form[drill]'
				defaultValue={r?.form.drill}
				label={t('drill')}
				filterkey={'forms'}
			/>
			<Input name='form[location]' defaultValue={r?.form.location} label={t('location')} />
			<Input
				decimal
				name='form[shoot_number]'
				defaultValue={r?.form.shoot_number}
				label={t('shoot_number')}
			/>
			<Input
				decimal
				name='form[drilling_time]'
				defaultValue={r?.form.drilling_time}
				label={t('drilling_time')}
			/>
			<Input
				decimal
				name='form[broken_steel]'
				defaultValue={r?.form.broken_steel}
				label={t('broken_steel')}
			/>
			<Input
				decimal
				name='form[mechanical_time]'
				defaultValue={r?.form.mechanical_time}
				label={t('mechanical_time')}
			/>
			<VariableUnitsInput
				choices='feetOrMeters'
				name='form[spacing]'
				defaultValue={r?.form.spacing}
				label={t('spacing')}
			/>
			<VariableUnitsInput
				choices='feetOrMeters'
				name='form[burden]'
				defaultValue={r?.form.burden}
				label={t('burden')}
			/>
			<VariableUnitsInput
				choices='inchesOrMillimeters'
				name='form[drill_diameter]'
				defaultValue={r?.form.drill_diameter}
				label={t('drill_diameter')}
			/>
			<label>
				<BorerSelect
					name='form[borer]'
					defaultValue={r?.form.borer}
					label={t('borer')}
					prefered={'name'}
					filterkey={'forms'}
				/>
			</label>
			<DrillMatrix d={r?.form.matrix} withUnitSelect={true}/>
			<Comments name='form[comment]' defaultValue={r?.form.comment} class='mt-6' />
		</Report>
	)
}
export default DrillerReport