import { getTasks } from '@/api/resources'
import { ClientSelect, FuzzySelect, ProjectSelect } from '@/components/forms/FuzzySelect'
import Input from '@/components/forms/Input'
import { API_DATETIME_FMT, INPUT_DATETIME_FMT } from '@/constants'
import { isQueryLoading } from '@/helpers'
import { useQuery } from '@tanstack/react-query'
import { DateTime } from 'luxon'
import { useState } from 'preact/hooks'
import { useTranslation } from 'react-i18next'

type Props = {
	report?: Api.Report
	type?: ReportType
}

const ProjectHeader: FC<Props> = ({ ...p }) => {
	const { t } = useTranslation('forms')
	const now = DateTime.now()
	const executionTime = fmtDate(now, p.report?.date_in)
	const [projectId, setProjectId] = useState<number | undefined>()
	const {
		status: tasksStatus,
		fetchStatus: tasksFetchStatus,
		data: tasks,
	} = useQuery({
		queryKey: ['tasks'],
		queryFn: getTasks,
	})

	if (p.report && !projectId) {
		setProjectId(p.report.project_id)
	}

	return (
		<>
			<ProjectSelect
				required
				name='project_id'
				label={t('project')}
				onMatch={(match) => setProjectId((match as Api.Project)?.id)}
				selectedId={p.report?.project_id}
			/>
			<FuzzySelect<Api.Task>
				required
				name='task_id'
				label={t('task')}
				resource={tasks?.filter(task => task.project_id == projectId)}
				selectedId={p.report?.task_id}
				isLoading={isQueryLoading(tasksStatus, tasksFetchStatus)}
			/>
            <ClientSelect
				name='supplier_id'
				label={t('client')}
				selectedId={p.report?.supplier_id?.toString()}
			/>
			<Input
				className='span3'
				required
				max={now.toFormat(INPUT_DATETIME_FMT)}
				defaultValue={executionTime ?? now}
				type='datetime-local'
				name='date_in'
				label={t('execution_time')}
			/>
			<hr />
		</>
	)
}
export default ProjectHeader

function fmtDate(placeholder: DateTime, dateStr?: string): string {
	const date = dateStr ? DateTime.fromFormat(dateStr, API_DATETIME_FMT) : placeholder
	return date.toFormat(INPUT_DATETIME_FMT)
}
