import { getReceipts } from '@/api/purchase'
import Receipt from './Receipt'
import { useContext, useEffect, useMemo, useState } from 'preact/hooks'
import { useTranslation } from 'react-i18next'
import globalContext from '@/context'
import { useQuery } from '@tanstack/react-query'
import { isQueryLoading } from '@/helpers'
import Loading from '@/components/Loading'

const ApproveReceipt: FC<{ path: string }> = () => {
	const { t } = useTranslation('approve_receipt')
	const [s, actions] = useContext(globalContext)
	const {
		status: status,
		fetchStatus: fetchStatus,
		data: receipts,
	} = useQuery({
		queryKey: ['receipts'],
		queryFn: getReceipts,
	})

	if (isQueryLoading(status, fetchStatus)) {
		return (
			<Loading />
		)
	}

	return (
		<>
			<h1>{t('routes:approve_receipt')}</h1>
			{receipts?.length !== 0 ? (
				<table class='w-full table-fixed  rounded-md '>
					<thead class='items-center rounded-md bg-gray-100 px-4 py-2 text-lg text-teal-500 shadow-md'>
						<tr>
							<th class='w-1/12 p-2'></th>
							<th class='w-5/12 p-2 text-sm'>{t('receipt_number')}</th>
							<th class='w-1/4 p-2 text-sm'>{t('price')}</th>
							<th class='w-1/4 p-2 text-sm'>{t('approved')}?</th>
						</tr>
					</thead>
					<tbody class='items-center rounded-md px-4 py-2 shadow-sm'>
						{receipts?.map((receipt: any, id: number) => (
							<>
								<Receipt receipt={receipt} index={id} />
							</>
						))}
					</tbody>
				</table>
			) : (
				<h4 class='text-center'>{t('you_have_no_receipt_to_approve')}</h4>
			)}
		</>
	)
}
export default ApproveReceipt
