import { NavGrid, NavItem } from '@/components/NavGrid'
import { useEffect } from 'preact/hooks'
import { useTranslation } from 'react-i18next'
import { useQueryClient } from '@tanstack/react-query'
import ClockIcon from '~icons/bi/clock'
import ListCheckIcon from '~icons/bi/list-check'
import { getTimeEntriesLastMonth } from '@/api/reports'

const Index: FC<{ path: string }> = () => {
	const queryClient = useQueryClient()
	const { t } = useTranslation('routes')

	const prefetchTimeEntries = async () => {
		await queryClient.prefetchQuery({
			queryKey: ['time-entries'],
			queryFn: getTimeEntriesLastMonth
		})
	}
	
	useEffect(() => {
		prefetchTimeEntries()
	}, [])

	return (
		<NavGrid>
			<NavItem label={t('time_entries')} href='/mydata/time_entries' icon={ClockIcon} />
			<NavItem label={t('filled_daily_reports')} href='/mydata/filled_daily_reports' icon={ListCheckIcon} />
		</NavGrid>
	)
}
export default Index
