import { getTimeEntriesLastMonth } from '@/api/reports'
import Loading from '@/components/Loading'
import { DateTime } from 'luxon'
import { useTranslation } from 'react-i18next'
import { useQuery } from '@tanstack/react-query'

type Props = {
    path: string
}

const PlannedReportListing: FC<Props> = ({path, ...props}) => {
	const { t } = useTranslation('routes')
	const { data: timeEntries } = useQuery<Api.TimeEntry[]>({
		queryKey: ['time-entries'],
		queryFn: getTimeEntriesLastMonth
	})

	const plannedReports = timeEntries?.filter(value => value.planned_status == 'planned')

	if (timeEntries === undefined) {
		return <Loading />
	}

	if (plannedReports != undefined && plannedReports.length == 0) {
		return (
			<div class="flex justify-center">
				<h1>{t('forms:no_planned_reports')}</h1>
			</div>
		)
	}

	return (
		<ol class='grid grid-cols-2 gap-4'>
			{plannedReports?.filter(value => value.planned_status == 'planned')
				.map(r => (
				<li>
					<a
						href={`/planned_report/${r.id}`}
						class='block h-full w-full rounded-md bg-white px-4 py-4 shadow-sm'
					>
						<div class='mt-2 text-right text-sm text-gray-600'>
							{ r?.work_order?.work_order_id }
						</div>
						<div class='mt-2 text-right text-sm text-gray-600'>
							{ r?.project?.name }
						</div>
						<div class='mt-2 text-right text-sm text-gray-600'>
							{ r?.task?.name }
						</div>
						<div class='mt-2 text-right text-sm text-gray-600'>
							{DateTime.fromISO(r.date_in).toISODate()} 
						</div>
					</a>
				</li>
			)) 
		}
		</ol>
	)
}
export default PlannedReportListing
