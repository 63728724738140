import Input from '@/components/forms/Input'
import { INPUT_DATETIME_FMT } from '@/constants'
import { DateTime } from 'luxon'
import { useTranslation } from 'react-i18next'

type Props = {
	report?: Api.TimeEntry
	type?: ReportType
}

const TimeCard: FC<Props> = ({ report: r, ...p }) => {
	const { t } = useTranslation('forms')
	const now = DateTime.now()
	const oneHourAgo = DateTime.now().minus({ hours: 1 })
	const dateIn = fmtDate(oneHourAgo, r?.date_in)
	const dateOut = fmtDate(now, r?.date_out)

	return (
		<>
			<Input
				className='span3'
				required
				max={now.toFormat(INPUT_DATETIME_FMT)}
				defaultValue={dateIn ?? oneHourAgo}
				type='datetime-local'
				name='date_in'
				label={p.type === 'driver' ? t(`${p.type}:time_in`) : t('time_in')}
			/>
			<Input
				className='span3'
				required
				max={now.toFormat(INPUT_DATETIME_FMT)}
				defaultValue={dateOut ?? now}
				type='datetime-local'
				name='date_out'
				label={p.type === 'driver' ? t(`${p.type}:time_out`) : t('time_out')}
			/>
		</>
	)
}
export default TimeCard

export function fmtDate(placeholder: DateTime, dateStr?: string): string {
	const date = dateStr ? DateTime.fromISO(dateStr) : placeholder
	return date.toFormat(INPUT_DATETIME_FMT)
}
