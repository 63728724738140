import DailyReport from '@/components/DailyReport'
import Comments from '@/components/forms/Comments'
import Drawing, { useDrawing } from '@/components/forms/Drawing'
import EquipmentList from '@/components/forms/EquipmentList'
import Input from '@/components/forms/Input'
import { BonusesSelect } from '@/components/forms/MultiSelect'
import globalContext from '@/context'
import { useFilledTimeEntry } from '@/hooks'
import type { FunctionComponent as FC } from 'preact'
import { useContext } from 'preact/hooks'
import { useTranslation } from 'react-i18next'

type Props = {
	path: string
	report_id?: number
	disabled?: boolean
}

const DailyProject: FC<Props> = (p) => {
	const { t } = useTranslation('daily_project')
	const [, actions] = useContext(globalContext)
	const timeEntry = useFilledTimeEntry(p.report_id)
	const { setBlob: setSignature, append: appendSignature } = useDrawing(
		'images[signature]',
		'signature.png'
	)
	const submitArgs = {
		async process(fd: FormData) {
			appendSignature(fd)
			if (fd.get('comment')?.toString().trim().length === 0) fd.delete('comment')
		}
	}

	return (
		<DailyReport timeEntry={timeEntry} type="daily_project" submitArgs={submitArgs} disabled={p.disabled} translation={'daily_project'}>
			<Input type='file' accept='image/*' multiple name='images[]' label={t('photos')} />
			<hr />
			<label>
				{t('bonuses')}
				<BonusesSelect name='bonuses[]' />
			</label>
			<EquipmentList
				equipments={timeEntry?.equipment}
				translation='daily_project'
			/>
			<Comments name='comment' label={t('comments')} />
			<Drawing label={t('forms:signature')} setBlob={setSignature} />
		</DailyReport>
	)
}
export default DailyProject
