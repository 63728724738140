import type { TFunction } from 'i18next'
import { useState, type StateUpdater } from 'preact/hooks';

type Props = {
	t: TFunction;
	options: string[];
	d?: any
	callback?: StateUpdater<string>
	prefered?: string
}

const Options: FC<Props> = ({ t, options, d, ...p }) => {
	const [ selected, setSelected ] = useState('')

	var handleClick = (val: string) => {
		setSelected(val)
		if (p.callback) p.callback(val)
	}

	return (
		<>
			<option value=''>{t('forms:select_an_option')}</option>
			{options.map(o => (
				<option selected={p.prefered == 'name' ? t(o) === d : o === d} value={p.prefered == 'name' ? t(o) : o} onClick={() => handleClick(o)}>
					{t(o)}
				</option>
			))}
		</>
	)
}
export default Options
