import ky from 'ky'
import { route } from 'preact-router'
import { env } from '@/constants'

const base = ky.create({
	prefixUrl: import.meta.env.VITE_API_BASE_URL,
	timeout: env.MODE == 'development' ? 100000 : 10000,
	retry: 3,
	headers: {
		Accept: 'application/json',
	},
	hooks: {
		afterResponse: [
			async (req, opts, res) => {
				switch (res.status) {
					case 422:
						const body = (await res.json()) as Api.Error
						const errors = Object.values(body.errors).flat()
						throw new InvalidArgumentsError(errors)
				}
			},
		],
	},
})

export const auth = base.extend({
	hooks: {
		afterResponse: [
			async (req, opts, res) => {
				switch (res.status) {
					case 401:
						// if (env.DEV) return
						route('/login', true)
						throw new UnauthorizedError()
				}
			},
		],
	},
})

export const api = auth.extend({
	hooks: {
		beforeRequest: [
			async req => {
				const apiToken = localStorage.getItem('api_token')
				req.headers.set('Authorization', `Bearer ${apiToken}`)
			},
		],
	},
})

export const resources = api.extend({
	headers: { 'Cache-Control': 'private, max-age=60' },
})

abstract class ApiError extends Error {}

export class UnauthorizedError extends ApiError {}

export class InvalidArgumentsError extends ApiError {
	constructor(public errors: string[]) {
		super()
	}
}
