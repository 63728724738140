import { useEffect, useState } from 'preact/hooks'
import { useTranslation } from 'react-i18next'
import DynamicFieldset from './DynamicFieldset'
import Input from './Input'
import DailyReportTravelingTimeCard from './DailyReportTravelingTimeCard'

type Props = {
	travelings: Api.EwHasEquipment[] | undefined
    translation: string
}

const TravelingList: FC<Props> = (p) => {
	const { t } = useTranslation(p.translation)
	const [ traveling, setTraveling ] = useState<Api.EwHasEquipment>({} as Api.EwHasEquipment)
    const [ travelingData, setTravelingData ] = useState<Api.EwHasEquipmentData[]>([])

    useEffect(() => {
        if (p.travelings) {
            const traveling = p.travelings.find((val) => val.category == 'personal_vehicle')
            setTraveling(traveling ?? {} as Api.EwHasEquipment)
            setTravelingData(traveling?.data)
        }
    }, [p.travelings])

	return (
		
        <DynamicFieldset
            data={ traveling?.data as any }
            label={t('traveling')}
            make={i => (
                <fieldset key={i}>
                    <DailyReportTravelingTimeCard
                        position={i}
                        data={travelingData[i]}
                    />
                    <Input 
                        className='span3'
                        autocomplete='on'
                        required
                        name={`transport[${i}][departure]`}
                        label={t('departure')}
                        defaultValue={travelingData[i]?.departure?.toString()}
                    />
                    <Input
                        className='span3'
                        autocomplete='on'
                        required
                        name={`transport[${i}][arrival]`}
                        label={t('arrival')}
                        defaultValue={travelingData[i]?.arrival?.toString()}
                    />
                    <Input
                        required
                        units='km'
                        name={`transport[${i}][km]`}
                        label={t('total_km')}
                        defaultValue={travelingData[i]?.km.toString()}
                    />
                </fieldset>
            )}
        />
		
	)

}
export default TravelingList
