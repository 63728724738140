import Btn from '@/components/forms/Btn'
import { useTranslation } from 'react-i18next'
import { useSubmit } from '@/hooks'

type Props = {
	submitArgs: Parameters<typeof useSubmit>[0]
} & JSX.HTMLAttributes<HTMLFormElement>

const Form: FC<Props> = ({ submitArgs, ...p }) => {
	const { t } = useTranslation('approve_receipt')
	const { handleSubmit, submitDisabled } = useSubmit(submitArgs)
	return (
		<form onSubmit={handleSubmit} {...p}>
			{p.children}
			<Btn submit disabled={submitDisabled}>
				{t('forms:submit')}
			</Btn>
		</form>
	)
}
export default Form
