import { postTimeEntry } from '@/api/reports'
import Btn from '@/components/forms/Btn'
import globalContext from '@/context'
import { useSubmit } from '@/hooks'
import { useContext, useState } from 'preact/hooks'
import { useTranslation } from 'react-i18next'
import { FuzzySelect, ProjectSelect } from './forms/FuzzySelect'
import TimeCard from './forms/TimeCard'
import TravelingList from './forms/TravelingList'
import Input from './forms/Input'
import { useQuery } from '@tanstack/react-query'
import { getTasks } from '@/api/resources'
import { isQueryLoading } from '@/helpers'

type Props = {
	type: TimeEntryType
	timeEntry: Api.TimeEntry | undefined
	translation: string
	withTraveling?: boolean
	submitArgs?: Partial<Parameters<typeof useSubmit>[0]>
	disabled?: boolean
} & JSX.HTMLAttributes<HTMLFormElement>

const DailyReport: FC<Props> = (p) => {
	const { t } = useTranslation(p.translation)
	const [, actions] = useContext(globalContext)
	const [project, setProject] = useState<Api.Project | undefined>(undefined)
	const projectId = project?.id
	const {
		status: tasksStatus,
		fetchStatus: tasksFetchStatus,
		data: tasks,
	} = useQuery({
		queryKey: ['tasks'],
		queryFn: getTasks,
	})

	if (p.timeEntry && !project) {
		setProject(p.timeEntry.project)
	}

	const { handleSubmit, submitDisabled } = useSubmit({
		process: p.submitArgs?.process,
		submit: p.submitArgs?.submit ?? postTimeEntry,
		async onSuccess(res) {
			if (p.submitArgs?.onSuccess) {
				await p.submitArgs?.onSuccess(res);
			} else {
				actions.toastInfo(t('toasts:form_submitted'));
				setTimeout(() => {
					history.pushState({}, '', '/reports');
					location.href = '/reports';
				}, 1000);
			}
		},
		onError(err) {
			if (err instanceof TypeError) {
				actions.toastError(t('toasts:network_error'))
			} else {
				actions.toastError(t('toasts:generic_error'))
			}
			return false;
		}
	})


	return (
		<>
			<h1>{t(`routes:${p.type}`)}</h1>
			<form onSubmit={handleSubmit}>
				<fieldset className='dummy' disabled={p.disabled}>
					<input type="hidden" name="id" value={p.timeEntry?.id} />
					<input type="hidden" name="myoctans_type" value={p.type} />
					<ProjectSelect
						required
						name='project_id'
						label={t('project')}
						onMatch={(match) => setProject(match as Api.Project)}
						defaultValue={p.timeEntry?.project_id?.toString()}
						disabled={p.type == 'planned_report' ? true : false}
					/>
					<FuzzySelect<Api.Task>
						required
						name='task_id'
						label={t('task')}
						disabled={p.type == 'planned_report' ? true : false}
						resource={tasks?.filter(task => task.project_id == projectId)}
						selectedId={p.timeEntry?.task?.myoctans_task_id}
						isLoading={isQueryLoading(tasksStatus, tasksFetchStatus)}
					/>
					<TimeCard report={p.timeEntry} />
					{
						p.withTraveling &&
						<TravelingList
							travelings={p.timeEntry?.ew_has_equipments}
							translation='daily_report'
						/>
					}
					{
						p.type != 'planned_report' && p.type != 'simplified_daily' ? 
							<>
								<Input
									units='minutes'
									name='lunch_time'
									label={t('lunch_time')}
									defaultValue={p.timeEntry?.lunch_time?.toString()}
								/>
								<Input
									className='span3'
									units='minutes'
									name='break_time'
									label={t('break_time')}
									defaultValue={p.timeEntry?.break_time?.toString()}
								/>
								<Input
									className='span3'
									units='hours'
									name='lost_time'
									label={t('lost_time')}
									defaultValue={p.timeEntry?.lost_time?.toString()}
								/>
							</> : ''
					}
					{
						p.type == 'simplified_daily' ?
							<Input
								class='span6'
								units='hours'
								name='traveling_time'
								label={t('traveling_hours')}
								defaultValue={p.timeEntry?.traveling_time?.toString()}
							/> : ''
					}
					
					{ p.children }
					{ !p.disabled && (
						<Btn disabled={submitDisabled} submit>
							{t('forms:submit')}
						</Btn>
					)}
				</fieldset>
			</form>
		</>
	)

}
export default DailyReport
