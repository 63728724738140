import { resources } from '@/api/helpers'

export async function getTasks(): Promise<Api.Task[]> {
	return await resources.get('tasks').json()
}

export async function getTasksByProjectId(projectId: number): Promise<Api.Task[]> {
	return await resources.get('tasks', { searchParams: { project_id: projectId } }).json()
}

export async function getEmployees(): Promise<Api.Employee[]> {
	const employees: Api.Employee[] = await resources.get('employees').json()
	return employees.map(e =>
		Object.assign(e, {
			get name(): string {
				return `${e.first_name} ${e.last_name}`
			},
		})
	)
}

export async function getEmployeeByMyoctansId(userId: number): Promise<Api.Employee> {
	return await resources.get(`employees`, {searchParams: { myoctans_id: userId }}).json()
}

export async function getProjects(): Promise<Api.Project[]> {
	return await resources.get('projects').json()
}

export async function getProjectsWithTask(): Promise<Api.Project[]> {
	return await resources.get('projects?with-task=1').json()
}

export async function getProfessions(): Promise<Api.Profession[]> {
	return resources.get('professions').json()
}

export async function getSuppliers(): Promise<Api.Supplier[]> {
	return resources.get('suppliers').json()
}

export async function getEquipments(): Promise<Api.Equipment[]> {
	return resources.get('equipment').json()
}

export async function getMaterials(): Promise<Api.Material[]> {
	return resources.get('materials').json()
}

export async function getBonuses(): Promise<Api.Bonus[]> {
	return resources.get('bonuses').json()
}

export async function getVehicles(): Promise<Api.Equipment[]> {
	return resources.get('equipment', { searchParams: { category: 'vehicle' } }).json()
}

export async function getDrills(): Promise<Api.Equipment[]> {
	return resources.get('equipment', { searchParams: { category: 'drill' } }).json()
}

export async function getSeismograph(): Promise<Api.Equipment[]> {
	return resources.get('equipment', { searchParams: { category: 'seismograph' } }).json()
}

export async function getBorers(): Promise<Api.Equipment[]> {
	return resources.get('equipment', { searchParams: { category: 'borer' } }).json()
}

export async function getStubResources(): Promise<Api.Resource[]> {
	return resources.get('stub').json()
}

export async function getNotifications(): Promise<Notification[]> {
	return resources.get('notifications').json()
}

export async function getLocations(): Promise<Api.Location[]> {
	return resources.get('locations').json()
}

export async function getPurchaseOrders(): Promise<Api.PurchaseOrder[]> {
	const purchaseOrders: Api.PurchaseOrder[] = await resources.get('purchase-orders').json()
	return purchaseOrders.map(e =>
		Object.assign(e, {
			get name(): string {
				return `${e.invoice_number}`
			},
		})
	)
}

export async function getAddresses() : Promise<Api.Address[]> {
	return resources.get('addresses').json();
}