import { useEffect, useState } from 'preact/hooks'
import { useTranslation } from 'react-i18next'
import Input from './Input'
import { DrillSelect } from '@/components/forms/FuzzySelect'
import VariableUnitsInput from './VariableUnitsInput'
import Checkbox from './Checkbox'

type Props = {
	drillingMachine: Api.EwHasEquipment[] | undefined
    translation: string
}

const ToggleableTransport: FC<Props> = (p) => {
	const { t } = useTranslation(p.translation)
	const [ drillingMachine, setDrillingMachine ] = useState<Api.EwHasEquipment>({} as Api.EwHasEquipment)
    const [ useDrillingMachine, setUseDrillingMachine ] = useState<boolean>(false)

    useEffect(() => {
        const drillingMachine = p.drillingMachine?.find((val) => val.category == 'drilling_machine')
        if (drillingMachine) {
            setDrillingMachine(drillingMachine)
            setUseDrillingMachine(true)
        }
    }, [p.drillingMachine])


	return (
		<div class='mt-6'>
            <Checkbox
                class='justify-center'
                label={t('used_drilling_machine')}
                defaultChecked={useDrillingMachine}
                onChange={() => setUseDrillingMachine(!useDrillingMachine)}
            />
            <fieldset disabled={!useDrillingMachine}>
                <DrillSelect
                    required
                    name='drilling_machine[id]'
                    label={t('select_drill')}
                    filterkey={'daily_report'}
                    defaultValue={drillingMachine?.equipment_id?.toString()}
                />
                <Input
                    className='span3'
                    required
                    integer
                    name='drilling_machine[start_time]'
                    label={t('start_time')}
                    defaultValue={drillingMachine?.data?.start_time?.toString()}
                />
                <Input
                    className='span3'
                    required
                    integer
                    name='drilling_machine[end_time]'
                    label={t('end_time')}
                    defaultValue={drillingMachine?.data?.end_time?.toString()}
                />
                <VariableUnitsInput
                    choices='litersOrGallons'
                    name='drilling_machine[fuel_liters]'
                    label={t('fuel')}
                    defaultValue={drillingMachine?.data?.fuel_liters?.toString()}
                />
            </fieldset>
        </div>
	)

}
export default ToggleableTransport
