import { FC } from 'react';
import { useState } from 'react';
import ArrowUpIcon from '~icons/bi/arrow-up'

type Props = {
	title: string;
	defaultCollapsed?: boolean;
	className? : string;
}

const CollapsibleFieldset: FC<Props> = (p) => {
	const [isCollapsed, setIsCollapsed] = useState(p.defaultCollapsed ?? false)

	const toggleCollapse = (event: any, moveUp: boolean) => {
		const scrollPositionBeforeToggle = window.scrollY
		const element = document.querySelector('fieldset')
		const elementHeightBeforeToggle = element?.offsetHeight || 0

		setIsCollapsed(!isCollapsed)

		setTimeout(() => {
			if (moveUp) {
				const elementHeightAfterToggle = element?.offsetHeight || 0
				const heightDifference = elementHeightAfterToggle - elementHeightBeforeToggle

				window.scrollTo(0, scrollPositionBeforeToggle + heightDifference)
			} else {
				window.scrollTo(0, scrollPositionBeforeToggle)
			}
		})
	}

	return (
		<>
			<fieldset hidden={isCollapsed} className={p.className}>
				<legend onClick={(e) => toggleCollapse(e, false)} style={'cursor: pointer;'}>
					{p.title} <span class={`text-xl`}>{isCollapsed ? '+' : '-'}</span>
				</legend>
				{p.children}
				<div className={'flex h-8 flex-col flex-wrap items-center justify-center rounded-xl shadow-sm mt-2'} onClick={(e) => toggleCollapse(e, true)}>
					<ArrowUpIcon/>
				</div>
			</fieldset>
			<fieldset hidden={!isCollapsed} className={p.className}>
				<legend onClick={(e) => toggleCollapse(e, false)} style={'cursor: pointer;'}>
					{p.title} <span class={`text-xl`}>{isCollapsed ? '+' : '-'}</span>
				</legend>
			</fieldset>
		</>
	)
}

export default CollapsibleFieldset;