import Comments from '@/components/forms/Comments'
import Drawing, { useDrawing } from '@/components/forms/Drawing'
import DynamicFieldset from '@/components/forms/DynamicFieldset'
import Input from '@/components/forms/Input'
import MultiSelect from '@/components/forms/MultiSelect'
import Report from '@/components/Report'
import { useFilledReport } from '@/hooks'
import ExplosivesFieldsets from '@/pages/reports/shooting_log/ExplosivesFieldsets'
import Seismograph from '@/pages/reports/shooting_log/Seismograph'
import Materials from '@/pages/reports/shooting_log/Materials'
import { useTranslation } from 'react-i18next'

const ShootingLogReport: FC<ReportProps> = p => {
	const { t } = useTranslation('shooting_log')
	const { setBlob: setMatrix, append: appendMatrix } = useDrawing(
		'form[matrix_diagram]',
		'matrix_diagram.png'
	)
	const { setBlob: setBlasting, append: appendBlasting } = useDrawing(
		'form[blasting_diagram]',
		'blasting_diagram.png'
	)
	const submitArgs = {
		async process(fd: FormData) {
			appendMatrix(fd)
			appendBlasting(fd)
		},
	}
	const r = useFilledReport(p.report_id)
	const weatherChoices = ['sunny', 'cloudy', 'rain', 'snow'].map(w => ({
		name: w,
		label: t(w),
	}))

	return (
		<Report
			type='shooting_log'
			report={r}
			disabled={p.disabled === 'disabled'}
			submitArgs={submitArgs}
		>
			<input type='hidden' name='version' defaultValue='2' />
			<Input name='form[location]' defaultValue={r?.form.location} label={t('location')} />
			<Input
				className='span3'
				name='form[shots]'
				defaultValue={r?.form.shots}
				label={t('shots')}
				decimal
			/>
			<Input
				className='span3'
				name='form[flights]'
				defaultValue={r?.form.flights}
				label={t('flights')}
				decimal
				min={1}
			/>
			<Input
				className='span2'
				name='form[temperature]'
				defaultValue={r?.form.temperature}
				label={t('temperature')}
				units='celsius'
			/>
			<label className='span4'>
				{t('wind')}
				<select name='form[wind]'>
					<option value=''>{t('forms:select_an_option')}</option>
					{[t('north_east'), t('north'), t('north_west'), t('west'), t('sout_west'), t('south'), t('south_east'), t('east')].map(location => (
						<option selected={location === r?.form.pickup_location}>{location}</option>
					))}
				</select>
			</label>
			<label>
				{t('weather_condition')}
				<MultiSelect
					prefix='form[weather]'
					choices={weatherChoices}
					d={r?.form.weather}
					class='mt-2'
				/>
			</label>
			<ExplosivesFieldsets form={r?.form} />
			<DynamicFieldset
				label={t('shooting_log:seismograph')}
				data={r?.form.seismograph}
				make={(i, d) => <Seismograph filterkey={'forms'} i={i} d={d} />}
			/>
			<Materials filterkey={'forms'} />
			<Input
				className='mb-8'
				name='form[sketch]'
				defaultValue={r?.form.sketch}
				label={t('sketch')}
				type='file'
				accept='image/*'
			/>
			<Drawing
				type='matrix'
				className='span3 w-full'
				setBlob={setMatrix}
				label={t('matrix')}
				drawing={r?.form?.matrix_diagram}
				disabled={p.disabled === 'disabled'}
			/>
			<Drawing
				type='blasting'
				className='span3 w-full'
				setBlob={setBlasting}
				label={t('blasting_diagram')}
				drawing={r?.form?.blasting_diagram}
				disabled={p.disabled === 'disabled'}
			/>
			<Comments class='mt-8' name='form[comments]' defaultValue={r?.form.comments} />
		</Report>
	)
}
export default ShootingLogReport
