import classNames from 'classnames'
import { StateUpdater, useState } from 'preact/hooks';

type Props = {
	name: string;
	checked?: string;
	choices: Record<any, string>;
	class?: string
	onChange?: (value: string, name?: string) => void
	prefered?: string
}

const Radio: FC<Props> = p => {
	const handleChange = (event: Event) => {
		if (p.onChange) {
			p.onChange((event.target as HTMLInputElement).value)
		}
		
	}
	return (
		<div class={classNames('flex items-center gap-4', p.class)}>
			{Object.entries(p.choices).map(([k, v]) => (
				<label class='shrink grow-0 normal-case'>
					<input required id={`radio-${k}`} class='mr-2' type='radio' name={p.name} value={p.prefered == 'name' ? v : k} checked={p.checked === (p.prefered == 'name' ? v : k)} onClick={handleChange}/>
					{v}
				</label>
			))}
		</div>
	)
}

export default Radio
