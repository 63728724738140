
import { getTimeEntriesLastMonth } from '@/api/reports'
import { DateTime } from 'luxon'
import { useTranslation } from 'react-i18next'
import { useQuery } from '@tanstack/react-query'

const FilledDailyReports: FC<{ path: string; type?: TimeEntryType }> = p => {
	const { t } = useTranslation('routes')
	const { data: timeEntries } = useQuery<Api.TimeEntry[]>({
		queryKey: ['time-entries'],
		queryFn: getTimeEntriesLastMonth
	})

	const reportRoute = (report: Api.TimeEntry) => {
		let route = ''
		if (report.myoctans_type == 'planned_report') {
			route = `/planned_report/${report.id}/disabled`
		} else {
			route = `/reports/${report.myoctans_type}/${report.id}/disabled`
		}
		return route
	}

	return (
		<ol class='grid grid-cols-2 gap-4'>
			{(timeEntries) && timeEntries
								.filter(val => val.myoctans_type != null)
								.map(r => (
					<li>
						<a
							href={reportRoute(r)}
							class='block h-full w-full rounded-md bg-white px-4 py-4 shadow-sm'
						>
							<div class='mt-2 text-right text-sm text-gray-600'>
                                { t(r.myoctans_type) }
                            </div>
                            <div class='mt-2 text-right text-sm text-gray-600'>
                                { r.task.name }
                            </div>
							<div class='mt-2 text-right text-sm text-gray-600'>
                                {DateTime.fromISO(r.date_in).toISODate()} 
							</div>
						</a>
					</li>
				))}
		</ol>
	)
}
export default FilledDailyReports
