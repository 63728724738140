import { ping } from '@/api/auth'
import { UnauthorizedError } from '@/api/helpers'
import NavBar from '@/components/NavBar'
import Toasts from '@/components/Toasts'
import { MINUTES } from '@/constants'
import globalContext, { useGlobals } from '@/context'
import { privateRoute } from '@/helpers'
import i18n from '@/i18n'
import Home from '@/pages'
import LogIn from '@/pages/login'
import Mydata from '@/pages/mydata'
import TimeEntries from '@/pages/mydata/time_entries'
import PurchaseIndex from '@/pages/purchase'
import ReceiptsIndex from '@/pages/purchase/receipts'
import ApproveReceipt from '@/pages/purchase/receipts/approve'
import ApproveSingleReceipt from '@/pages/purchase/receipts/approve/approveReceipt'
import SendReceipt from '@/pages/purchase/receipts/send'
import Reception from '@/pages/purchase/reception'
import ReportsIndex from '@/pages/reports'
import BlasterReport from '@/pages/reports/blaster'
import TimeEntry from '@/pages/reports/daily'
import DailyTasks from '@/pages/reports/daily_tasks'
import DrillerReport from '@/pages/reports/driller'
import DrillerProdReport from '@/pages/reports/driller_prod'
import DriverReport from '@/pages/reports/driver'
import FilledForms from '@/pages/reports/filled'
import ShootingLogReport from '@/pages/reports/shooting_log'
import Router from 'preact-router'
import { useEffect, useErrorBoundary, useState } from 'preact/hooks'
import { useTranslation } from 'react-i18next'
import { QueryClient, QueryClientProvider } from '@tanstack/react-query'
import { getNotifications } from '@/api/resources'
import { usePermission } from '@/hooks'
import SimplifiedTimeEntry from "@/pages/reports/simplifiedDaily";
import DailyDriller from '@/pages/reports/daily_driller/index'
import DailyProject from './pages/reports/daily_project'
import TimeMaterials from './pages/reports/time_material'
import FilledDailyReports from './pages/mydata/filled_reports'
import PlannedReportListing from './pages/planned_report/PlannedReportListing'
import PlannedReport from './pages/planned_report/PlannedReport'
import HealthSecurity from './pages/health_security'
import Take5 from './pages/health_security/take5'

const queryClient = new QueryClient({
	defaultOptions: {
		queries: {
			staleTime: 5 * MINUTES,
		},
	},
})

const App: FC = () => {
	const [s, actions] = useGlobals(queryClient)
	const [canBack, setCanBack] = useState(false)
	const { t } = useTranslation()

	const [error, resetError] = useErrorBoundary(err => {
		if (err instanceof UnauthorizedError) {
			actions.logOut()
			resetError()
		} else {
			actions.toastError(t('toasts:generic_error'))
			resetError()
		}
	})

	const isAuthed = () => {
		if (s.apiToken) return true
		else return false
	}

	const handleRoute = (e: { url: string }) => {
		switch (e.url) {
			case '/login':
			case '/signup':
			case '/':
				setCanBack(false)
				break

			default:
				setCanBack(true)
		}

		if (privateRoute()) {
			ping()
			getNotifications().then(items => {
				actions.setNotifications(items)
			})
		}
	}

	useEffect(() => {
		i18n.changeLanguage(s.lang)
		handleRoute({ url: location.pathname })
		addEventListener('visibilitychange', () => {
			if (privateRoute()) ping()
		})
	}, [])

	return (
		<QueryClientProvider client={queryClient} context={globalContext}>
			{/* @ts-ignore */}
			<globalContext.Provider value={[s, actions]}>
				<div class='container mx-auto max-w-xl px-6 py-8'>
					<NavBar canBack={canBack} />
					<main>
						{/* @ts-ignore */}
						<Router onChange={handleRoute}>
							<Home path='/' />
							<LogIn path='/login' />
							{/* Reports */}
							<ReportsIndex path='/reports' />
							<TimeEntry path='/reports/daily/:report_id?/:disabled?' />
							<SimplifiedTimeEntry path='/reports/simplified_daily/:report_id?/:disabled?' />
							<DailyDriller path='/reports/daily_driller/:report_id?/:disabled?' />
							<DailyProject path='/reports/daily_project/:report_id?/:disabled?' />
							{
								usePermission('daily_report', 'time_material') &&
								<TimeMaterials path='/reports/time_material/:report_id?/:disabled?'/>
							}
							
							<FilledForms path='/reports/filled/:type?' />
							{
								usePermission('daily_report','driller') &&
								<DrillerReport path='/reports/driller/:report_id?/:disabled?' />
							}
							{
								usePermission('daily_report','driller_prod') &&
								<DrillerProdReport path='/reports/driller_prod/:report_id?/:disabled?' />
							}
							{
								usePermission('daily_report','blaster') &&
								<BlasterReport path='/reports/blaster/:report_id?/:disabled?' />
							}
							{
								usePermission('daily_report','driver') &&
								<DriverReport path='/reports/driver/:report_id?/:disabled?' />
							}
							{
								usePermission('daily_report','shooting_log') &&
								<ShootingLogReport path='/reports/shooting_log/:report_id?/:disabled?' />
							}
							{
								usePermission('daily_report','daily_tasks') &&
								<DailyTasks path='/reports/daily_tasks/:report_id?/:disabled?' />
							}
							{/* Purchase */}
							<PurchaseIndex path='/purchase' />
							
							{/* <Requisition path='/purchase/requisition' /> */}
							<ReceiptsIndex path='purchase/receipts' />
							<SendReceipt path='/purchase/receipts/send' />
							<ApproveReceipt path='/purchase/receipts/approve' />
							<ApproveSingleReceipt path={`/purchase/receipts/approve/:receipt_id`} />
							<Reception path={`/purchase/reception`} />
							<Mydata path='/mydata' />
							<TimeEntries path='/mydata/time_entries' />
							<FilledDailyReports path='/mydata/filled_daily_reports' />
							<PlannedReportListing path='/planned_report' />
							<PlannedReport path={'/planned_report/:report_id/:disabled?'} />
							<HealthSecurity path={'/health_security'} />
							<Take5 path={'/health_security/take5'} />
						</Router>
					</main>
					<Toasts messages={s.messages} />
				</div>
			</globalContext.Provider>
		</QueryClientProvider>
	)
}
export default App
