import Comments from '@/components/forms/Comments'
import Drawing, { useDrawing } from '@/components/forms/Drawing'
import globalContext from '@/context'
import { useFilledTimeEntry } from '@/hooks'
import type { FunctionComponent as FC } from 'preact'
import { useContext } from 'preact/hooks'
import { useTranslation } from 'react-i18next'
import { BonusesSelect} from "@/components/forms/MultiSelect";
import DailyReport from '@/components/DailyReport'
import { putTimeEntry } from '@/api/reports'

type Props = {
    path: string
    report_id: number
    disabled?: 'disabled'
}

const PlannedReport: FC<Props> = ({path, report_id, disabled, ...props}) => {
    const { t } = useTranslation('daily_report')
    const [, actions] = useContext(globalContext)
    const timeEntry = useFilledTimeEntry(report_id)
    const { setBlob: setSignature, append: appendSignature } = useDrawing(
        'images[signature]',
        'signature.png'
    )

    const submitArgs = {
        async process(fd: FormData) {
            appendSignature(fd)
            if (fd.get('comment')?.toString().trim().length === 0) fd.delete('comment')
        },
        submit: putTimeEntry,
        onSuccess: function() {
            setTimeout(() => {
                history.pushState({}, '', '/');
                location.href = '/';
            }, 1000);
        }
    }

    return (
        
        <DailyReport
            type={'planned_report'}
            timeEntry={timeEntry}
            submitArgs={submitArgs}
            translation='daily_report'
            disabled={disabled}
            withTraveling={false}
        >
            <hr />
            <label>
                {t('bonuses')}
                <BonusesSelect
                    name='bonuses[]'
                    defaultValue={timeEntry?.bonuses}
                />
            </label>
            <Comments
                name='comment'
                defaultValue={timeEntry?.comment?.toString()}
            />
            <Drawing
                label={t('forms:signature')}
                setBlob={setSignature}
            />

            <input type="hidden" name="planned_status" value={timeEntry?.planned_status} />
            <input type="hidden" name="_method" value="PUT"/>

        </DailyReport>
        
    )
}
export default PlannedReport