import Radio from '@/components/forms/Radio'
import { API_DATETIME_FMT, INPUT_DATETIME_FMT } from '@/constants'
import { useTranslation } from 'react-i18next'
import { DateTime } from 'luxon'
import Input from '@/components/forms/Input'

type Props = { 
    d?: {
        date_in: string
        date_out: string
        type: string
    }
    defaultValue?: Record<string, any>
}

const WorkQuarter: FC<Props> = ({ d, ...p }) => {
	const { t } = useTranslation('driller')
    const now = DateTime.now()
    const oneHourAgo = DateTime.now().minus({ hours: 1 })
    const dateIn = fmtDate(oneHourAgo, d?.date_in)
    const dateOut = fmtDate(now, d?.date_out)

    const workQuarters: Record<any, string> = {
        day: t('day'),
        evening: t('evening'),
        night: t('night'),
    }

	return (
		<>
            <fieldset>
                <legend>{t('quarter')}</legend>
                <Radio
                    name='form[quarter]'
                    checked={d?.type ? d.type : t('day')}
                    choices={workQuarters}
                    prefered='name'
                />
                <Input
                    className='span3'
                    required
                    max={now.toFormat(INPUT_DATETIME_FMT)}
                    defaultValue={dateIn}
                    type='datetime-local'
                    name='date_in'
                    label={t('start_time')}
                />
                <Input
                    className='span3'
                    required
                    max={now.toFormat(INPUT_DATETIME_FMT)}
                    defaultValue={dateOut}
                    type='datetime-local'
                    name='date_out'
                    label={t('end_time')}
                />
            </fieldset>
		</>
	)
}
export default WorkQuarter


function fmtDate(placeholder: DateTime, dateStr?: string): string {
	const date = dateStr ? DateTime.fromISO(dateStr) : placeholder
	return date.toFormat(INPUT_DATETIME_FMT)
}