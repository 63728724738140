import Materials from '@/pages/reports/blaster/Materials'
import Report from '@/components/Report'
import Comments from '@/components/forms/Comments'
import DynamicFieldset from '@/components/forms/DynamicFieldset'
import Input from '@/components/forms/Input'
import { useFilledReport } from '@/hooks'
import { useTranslation } from 'react-i18next'
import Drawing, { useDrawing } from '@/components/forms/Drawing'

const BlasterReport: FC<ReportProps> = p => {
	const { t } = useTranslation('blaster')
	const r = useFilledReport(p.report_id)
	const { setBlob: setSignature, append: appendSignature } = useDrawing(
		'form[signature]',
		'signature.png'
	)
	const { setBlob: setClientSignature, append: appendClientSignature } = useDrawing(
		'form[client_signature]',
		'client_signature.png'
	)

	const submitArgs = {
		async process(fd: FormData) {
			appendSignature(fd)
			appendClientSignature(fd)
		}
	}

	return (
		<Report type='blaster' submitArgs={submitArgs} report={r} disabled={p.disabled === 'disabled'} headerTitle={t('header_title')}>
			<input type='hidden' name='version' defaultValue='2' />
			<label>
				{t('pickup_location')}
				<select name='form[pickup_location]'>
					<option value=''>{t('forms:select_an_option')}</option>
					{['Bernier', 'Granby', 'Sherbrooke', 'Ste-Sophie', 'Québec', 'Yamachiche', 'Port-Daniel', 'Rimouski', 'Autre poudrière'].map(location => (
						<option selected={location === r?.form.pickup_location}>{location}</option>
					))}
				</select>
			</label>
			<fieldset>
				<legend>{t('main_trench')}</legend>
				<Input
					decimal
					name='form[main_trench][1]'
					defaultValue={r?.form.main_trench[1]}
					label={t('1_hole')}
				/>
				<Input
					decimal
					name='form[main_trench][2]'
					defaultValue={r?.form.main_trench[2]}
					label={t('2_holes')}
				/>
				<Input
					decimal
					name='form[main_trench][3]'
					defaultValue={r?.form.main_trench[3]}
					label={t('3_holes')}
				/>
			</fieldset>
			<fieldset>
				<legend>{t('secondary_trench')}</legend>
				<Input
					decimal
					name='form[secondary_trench][1]'
					defaultValue={r?.form.secondary_trench[1]}
					label={t('1_hole')}
				/>
				<Input
					decimal
					name='form[secondary_trench][2]'
					defaultValue={r?.form.secondary_trench[2]}
					label={t('2_holes')}
				/>
				<Input
					decimal
					name='form[secondary_trench][3]'
					defaultValue={r?.form.secondary_trench[3]}
					label={t('3_holes')}
				/>
			</fieldset>
			<fieldset>
				<legend>{t('infra_m3')}</legend>
				<Input
					name='form[infra_m3][1]'
					defaultValue={r?.form.infra_m3[1]}
					label={t('m3_mass')}
				/>
			</fieldset>
			<fieldset>
				<legend>{t('infra_m2')}</legend>
				<Input
					name='form[infra_m2][1]'
					defaultValue={r?.form.infra_m2[1]}
					label={t('m2_surface')}
				/>
			</fieldset>
			<Materials filterkey={'forms'} />
			<DynamicFieldset
				label={t('pictures')}
				make={(i, d) => (
					<div>
						<Input
							name={`form[pictures][${i}][file]`}
							defaultValue={d?.file}
							type='file'
							accept='image/*'
							label={t('picture')}
						/>
						<Comments name={`form[pictures][${i}][comment]`} defaultValue={d?.comment} />
					</div>
				)}
			/>
			<Comments name='form[comments]' defaultValue={r?.form.comments} />
			<Drawing label={t('forms:signature')} setBlob={setSignature} />
			<Drawing label={t('forms:client_signature')} setBlob={setClientSignature} />
		</Report>
	)
}
export default BlasterReport
