import Materials from '@/pages/reports/blaster/Materials'
import Report from '@/components/Report'
import DynamicFieldset from '@/components/forms/DynamicFieldset'
import Input from '@/components/forms/Input'
import { useFilledReport } from '@/hooks'
import { useTranslation } from 'react-i18next'
import { MaterialSelect } from '@/components/forms/FuzzySelect'
import Professions from './Professions'
import Drawing, { useDrawing } from '@/components/forms/Drawing'
import { useContext } from 'preact/hooks'
import globalContext from '@/context'
import Radio from '@/components/forms/Radio'
import { postReceipt } from '@/api/purchase'
import { useQuery } from '@tanstack/react-query'
import { getBonuses } from '@/api/resources'

type WorkCategories = "amiante" | "seche" | "other_contaminants"

const workCategories: Record<WorkCategories, string> = {
	amiante: "Amiante",
	seche: "Sèche",
	other_contaminants: "Autres Contaminants"
}

const TimeMaterials: FC<ReportProps> = p => {
	const { t } = useTranslation('time_material')
	const bonuses = useQuery({
		queryKey: ['bonuses'],
		queryFn: getBonuses,
	})
	const r = useFilledReport(p.report_id)
	const [, actions] = useContext(globalContext);
	const { setBlob: setSignature, append: appendSignature } = useDrawing(
		'form[signature]',
		'signature.png'
	)
	const submitArgs = {
		submit: postReceipt,
		async process(fd: FormData) {
			appendSignature(fd)
		},
		async onSuccess() {
			actions.toastInfo(t('receipt_submitted'))
			history.back()
		},
	}

	return (
		<Report submitArgs={submitArgs} type='time_material' report={r} disabled={p.disabled === 'disabled'}>
			<label>{t('work_details')}</label>
			<Radio name="form[category]" checked={r?.form?.category} choices={workCategories} />
			<Professions defaultValue={r?.form} bonus={bonuses.data as Api.Bonus[]}/>
			<Materials filterkey={'forms'} d={r?.form?.materials}/>
			<DynamicFieldset
					data={r?.form?.other_materials}
					label={t('other_materials')}
					make={i => (
						<fieldset key={i}>
							<MaterialSelect
								className='span3'
								required
								name={`form[other_materials][${i}][id]`}
								defaultValue={r?.form?.other_materials[i].id}
								label={t('name')}
								filterkey={'forms'}
							/>
							<Input
								className='span3'
								required
								integer
								defaultValue={r?.form?.other_materials[i].quantity}
								name={`form[other_materials][${i}][quantity]`}
								label={t('quantity')}
							/>
						</fieldset>
					)}
				/>
			<Drawing label={t('forms:signature')} setBlob={setSignature} drawing={r?.form?.signature} />
		</Report>
	)
}
export default TimeMaterials
