import { useEffect, useState } from 'preact/hooks'
import { useTranslation } from 'react-i18next'
import Input from './Input'
import Checkbox from './Checkbox'

type Props = {
	transport: Api.EwHasEquipment[] | undefined
    translation: string
}

const ToggleableTransport: FC<Props> = (p) => {
	const { t } = useTranslation(p.translation)
	const [ transport, setTransport ] = useState<Api.EwHasEquipment>({} as Api.EwHasEquipment)
    const [ useTransport, setUseTransport ] = useState<boolean>(false)

    useEffect(() => {
        const transports = p.transport?.find((val) => val.category == 'vehicle')
        if (transports) {
            setTransport(transports)
            setUseTransport(true)
        }
    }, [p.transport])

	return (
		<div class='mt-6'>
            <Checkbox
                class='justify-center'
                label={t('company_transport')}
                defaultChecked={useTransport}
                onChange={() => setUseTransport(!useTransport)}
            />
            <fieldset disabled={!useTransport}>
                <Input
                    autocomplete='on'
                    required
                    name='companyTransport[departure]'
                    label={t('departure')}
                    defaultValue={transport?.data?.departure}
                />
                <Input
                    autocomplete='on'
                    required
                    name='companyTransport[arrival]'
                    label={t('arrival')}
                    defaultValue={transport?.data?.arrival}
                />
                <Input
                    className='span3'
                    required
                    units='km'
                    name='companyTransport[km]'
                    label={t('total_km')}
                    defaultValue={transport?.data?.km}
                />
                <Input
                    className='span3'
                    required
                    units='hours'
                    name='companyTransport[hours]'
                    label={t('total_hours')}
                    defaultValue={transport?.data?.hours.toString()}
                />
            </fieldset>
        </div>
        
		
	)

}
export default ToggleableTransport
