import { api } from '@/api/helpers'
import { DateTime } from 'luxon'

export async function postTimeEntry(body: FormData): Promise<unknown> {
	return api.post('time-entries', { body })
}

export async function getTimeEntries(): Promise<Api.TimeEntry[]> {
	return api.get('time-entries').json()
}

export async function postReport(body: FormData): Promise<unknown> {
	return api.post('forms', { body })
}

export async function putReport(body: FormData): Promise<unknown> {
	return api.post(`forms/${body.get('id')}`, { body })
}

export async function getReports(): Promise<Api.Report[]> {
	return api.get('forms').json()
}

export async function getTimeEntriesLastMonth(): Promise<Api.TimeEntry[]> {
	const currentDate = DateTime.now()
	return api.get('time-entries', { searchParams: { start_date: currentDate.minus({ months: 1}).toSQLDate(), end_date: currentDate.toSQLDate() } })
				.json()
}

export async function putTimeEntry(body: FormData): Promise<unknown> {
	return api.post(`time-entries/${body.get('id')}`, {body})
}

export async function getDailyReports(): Promise<Api.DailyReport[]> {
	return await api.get('daily-reports').json()
}

export async function postDailyReport(body: FormData): Promise<unknown> {
	return api.post('daily-reports', { body })
}