import { getTasks } from '@/api/resources'

import { AddressSelect, ProjectSelect, FuzzySelect } from '@/components/forms/FuzzySelect'
import CollapsibleFieldset from '@/components/CollapsibleFieldset'
import Input from '@/components/forms/Input'
import { INPUT_DATETIME_FMT } from '@/constants'
import { isQueryLoading } from '@/helpers'
import { useQuery } from '@tanstack/react-query'
import { DateTime } from 'luxon'
import { StateUpdater, useState } from 'preact/hooks'
import { useTranslation } from 'react-i18next'
import DrillEquipmentSelect from '@/pages/reports/driller_prod/DrillEquipmentSelect'
import WorkQuarter from '@/pages/reports/driller_prod/WorkQuarter'
import { makeDrillEquipment } from '@/pages/reports/driller_prod/utils'


type Props = {
	report?: Api.Report
	type?: ReportType
	disabled?: boolean
	units: string
	drilllingType: string
	setUnits: StateUpdater<string>
	setDrillingType: StateUpdater<string>
}

const DrillHeader: FC<Props> = ({ report: r, ...p }) => {
	const { t } = useTranslation('forms')
	const [project, setProject] = useState<Api.Project | undefined>()
	const {
		status: tasksStatus,
		fetchStatus: tasksFetchStatus,
		data: tasks,
	} = useQuery({
		queryKey: ['tasks'],
		queryFn: getTasks,
	})
	const now = DateTime.now()
	const executionTime = r?.date_in ? DateTime.fromISO(r.date_in) : now
	const defaultCollapsed = r != undefined && !p.disabled

	return (
		<>
			<CollapsibleFieldset title={t('general_information')} defaultCollapsed={defaultCollapsed}>
				<ProjectSelect
					required
					name='project_id'
					label={t('project')}
					onMatch={(match) => setProject(match as Api.Project)}
					defaultValue={r?.project_id?.toString()}
				/>
				<FuzzySelect<Api.Task>
					required
					name='task_id'
					label={t('task')}
					resource={tasks?.filter(task => task.project_id == project?.id)}
					selectedId={r?.task_id}
					isLoading={isQueryLoading(tasksStatus, tasksFetchStatus)}
				/>
				<AddressSelect
					readonly
					defaultValue={r?.form?.work_execution_address}
					selectedId={project?.supplier_work_execution_address_id}
					name='form[work_execution_address]'
					label={t('work_execution_address')}
					prefered='name'
				/>
				<Input
					name='form[drilling_block]'
					value={r?.form?.drilling_block}
					label={t('driller:drilling_block')}
				/>
				<WorkQuarter d={{type: r?.form?.quarter, date_in: r?.date_in, date_out: r?.date_out}}/>
				<DrillEquipmentSelect defaultValue={makeDrillEquipment(r)}/>

				<label>{t('driller:drilling_type')}
                    <select name={`form[drill_type]`} onChange={(e) => p.setDrillingType(e.target?.value)}>
                        {/* <option value=''>{t('forms:select_an_option')}</option> */}
                        <option selected={p.drilllingType == 'mine'} value='mine'>{t('driller:mine')}</option>
                        {/* <option value='quarry'>{t('quarry')}</option> */}
                    </select>
                </label>
				<div>
					<label>{t('units')}</label>
					<select name={`form[units]`} onChange={(e: Event) => p.setUnits((e.target as HTMLInputElement).value )}>
						<option selected={p.units == 'meters'} value='meters'>{t('meters')}</option>
						<option selected={p.units == 'feet'} value='feet'>{t('feet')}</option>
					</select>
				</div>
			</CollapsibleFieldset>
			<hr />
		</>
	)
}
export default DrillHeader
