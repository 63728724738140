import Checkbox from '@/components/forms/Checkbox'
import Comments from '@/components/forms/Comments'
import Drawing, { useDrawing } from '@/components/forms/Drawing'
import Input from '@/components/forms/Input'
import { BonusesSelect } from '@/components/forms/MultiSelect'
import globalContext from '@/context'
import { useFilledTimeEntry } from '@/hooks'
import type { FunctionComponent as FC } from 'preact'
import { useContext } from 'preact/hooks'
import { useTranslation } from 'react-i18next'
import DailyReport from '@/components/DailyReport'
import EquipmentList from '@/components/forms/EquipmentList'
import MaterialList from '@/components/forms/MaterialList'
import OthersList from '@/components/forms/OthersList'
import DrillingMachineList from '@/components/forms/DrillingMachineList'
import ToggleableTransport from '@/components/forms/ToggleableTransport'

type Props = {
	path: string
	report_id?: number
	disabled?: boolean
}

const TimeEntry: FC<Props> = (p) => {
	const { t } = useTranslation('daily_report')
	const timeEntry = useFilledTimeEntry(p?.report_id)
	const [, actions] = useContext(globalContext)
	const { setBlob: setSignature, append: appendSignature } = useDrawing(
		'images[signature]',
		'signature.png'
	)
	const submitArgs = {
		async process(fd: FormData) {
			appendSignature(fd)
			if (fd.get('comment')?.toString().trim().length === 0) fd.delete('comment')
		}
	}

	return (
		<>			
			<DailyReport timeEntry={timeEntry} withTraveling={true} type="daily" submitArgs={submitArgs} disabled={p.disabled} translation={'daily_report'} >
				<hr />
				<Input
					type='file'
					accept='image/*'
					multiple
					name='images[]'
					label={t('pictures')}
					defaultValue={timeEntry?.images?.toString()}
				/>
				<EquipmentList
					equipments={timeEntry?.equipment} 
					translation='daily_report'
				/>
				<MaterialList
					materials={timeEntry?.materials}
					translation='daily_report'
				/>
				<OthersList
					others={timeEntry?.others}
					translation='daily_report'
				/>
				<ToggleableTransport
					transports={timeEntry?.ew_has_equipments}
					translation='daily_report'
				/>
				<DrillingMachineList
					drillingMachines={timeEntry?.ew_has_equipments}
					translation='daily_report'
				/>
				<Checkbox
					name='is_ccq'
					label={t('ccq')}
					defaultValue={timeEntry?.is_ccq ? 'on' : ''}
				/>
				<label>
					{t('bonuses')}
					<BonusesSelect
						name='bonuses[]'
						defaultValue={timeEntry?.bonuses}
					/>
				</label>
				<Comments
					name='comment'
					defaultValue={timeEntry?.comment}
				/>
				<Drawing
					label={t('forms:signature')}
					setBlob={setSignature}
				/>
			</DailyReport>
		</>
	)
}
export default TimeEntry
