import React from 'react';
import Input from './Input';
import { useTranslation } from 'react-i18next';

interface LoadingInputProps {
    label: string
    placeholder?: string
    className?: string
}

const LoadingInput: React.FC<LoadingInputProps> = ({
    label,
    placeholder,
    className,
    ...p
}) => {
    const { t } = useTranslation()
    return (
            <Input
                label={label}
                placeholder={placeholder ?? t('forms:loading')}
                className={`input-loading ${className}`}
                {...p}
            />
    );
};

export default LoadingInput;