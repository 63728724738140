import { Fragment } from 'preact'
import classNames from 'classnames'
import NotificateIcon from '~icons/clarity/notification-line'
import { Menu, Transition } from '@headlessui/react'
import { putNotification } from '@/api/purchase'
import { useContext, useState, useEffect } from 'preact/hooks'
import globalContext from '@/context'
import { route } from 'preact-router'
import { useTranslation } from 'react-i18next'
import { Icon } from '@iconify/react'
import dot01Xs from '@iconify/icons-ci/dot-01-xs'
import receiptIcon from '@iconify/icons-bxs/receipt'
import { getNotifications } from '@/api/resources'
import notificationSolidBadged from '@iconify/icons-clarity/notification-solid-badged'
import notificationSolid from '@iconify/icons-clarity/notification-solid'

const NotificationBadged: FC = () => {

	const { t } = useTranslation('navbar')
	const [s, actions] = useContext(globalContext)
	const [showAll, setShowAll] = useState(true)

	useEffect(() => {
		if (s.apiToken !== null) {
			getNotifications().then(items => {
				actions.setNotifications(items)
			})
		}
	}, [])
	// setInterval(() => {
	// 	getNotifications().then(items => {
	// 		setNotifications(items)
	// 	})
	// }, 60000)
	let data

	if (showAll) data = s.notifications
	else data = s.notifications.filter(item => item.read_at === null)

	const handleNotificationClick = async (notification: Notification) => {
		await putNotification(notification.id)
		await getNotifications().then(items => {
			actions.setNotifications(items)
		})
		route(`/purchase/receipts/approve/${notification.data.receipt_id}`, true)
	}
	return (
		<Menu as='div' className='relative mr-3 inline-block  text-left'>
			<div>
				<Menu.Button className='flex'>
					<span className='relative inline-block'>
						<NotificateIcon width='28' height='28' />
						{s.notifications.filter(item => item.read_at === null).length > 0 && (
							<span className='absolute top-1 right-1 inline-flex translate-x-1/2 -translate-y-1/2 transform items-center justify-center rounded-full bg-red-600 px-[6px] py-[4px] text-[10px] leading-none text-red-100'>
								{s.notifications.filter(item => item.read_at === null).length}
							</span>
						)}
					</span>
				</Menu.Button>
			</div>

			<Transition
				as={Fragment}
				enter='transition ease-out duration-100'
				enterFrom='transform opacity-0 scale-95'
				enterTo='transform opacity-100 scale-100'
				leave='transition ease-in duration-75'
				leaveFrom='transform opacity-100 scale-100'
				leaveTo='transform opacity-0 scale-95'
			>
				<Menu.Items className='absolute right-0 mt-3 max-h-screen w-72 origin-top-right divide-y divide-gray-100 overflow-x-hidden rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none'>
					<div className='py-1'>
						<Menu.Item>
							<>
								<p className='block px-4 py-3 text-center text-base text-teal-500'>
									{t('navbar:notifications')}
								</p>
								<div class='mb-2 flex items-center justify-around text-sm text-teal-500'>
									<p
										className={classNames(
											showAll ? 'bg-teal-500 text-white ' : '',
											'w-20 rounded-xl border border-teal-500 text-center hover:cursor-pointer hover:bg-teal-500 hover:text-white'
										)}
										onClick={() => setShowAll(true)}
									>
										{t('navbar:all')}
									</p>
									<p
										className={classNames(
											!showAll ? 'bg-teal-500 text-white' : '',
											'w-20 rounded-xl border border-teal-500 text-center hover:cursor-pointer hover:bg-teal-500 hover:text-white'
										)}
										onClick={() => setShowAll(false)}
									>
										{t('navbar:unread')}
									</p>
								</div>
							</>
						</Menu.Item>
					</div>
					{data.length === 0 && (
						<div className='flex items-center justify-around py-2 hover:cursor-pointer'>
							<Menu.Item>
								<div className={'m-2 block text-center text-xs text-gray-700'}>
									{showAll ? (
										<div class='flex flex-col items-center'>
											<Icon
												icon={notificationSolid}
												width='30'
												height='30'
												className='text-teal-500'
												align='center'
											/>
											<div class='text-md mt-2 text-center'>{t('you_have_no_notifications')}</div>
										</div>
									) : (
										<div class='flex flex-col items-center'>
											<Icon
												icon={notificationSolidBadged}
												width='30'
												height='30'
												className='text-teal-500'
												align='center'
											/>
											<div class='text-md mt-2 text-center'>
												{t('you_have_no_unread_notifications')}
											</div>
										</div>
									)}
								</div>
							</Menu.Item>
						</div>
					)}
					{data?.map((notification, id) => (
						<div className='relative py-2 hover:cursor-pointer hover:bg-gray-100' key={id}>
							<Menu.Item>
								{({ active }) => (
									<div class='flex items-center justify-around'>
										{notification.read_at == null ? (
											<div class='absolute -right-2 top-1'>
												<Icon icon={dot01Xs} color='red' width='40' height='40' inline={true} />
											</div>
										) : (
											''
										)}
										<div class='pl-2'>
											<Icon
												icon={receiptIcon}
												width='20'
												height='20'
												color='#14b8a6'
												inline={true}
											/>
										</div>
										<div
											onClick={() => handleNotificationClick(notification)}
											className={classNames(
												active ? 'bg-gray-100 text-gray-900' : 'text-gray-700',
												'block py-2 pr-4 text-center text-xs'
											)}
										>
											{t('you_have_a_new_receipt_to_approve')}
										</div>
									</div>
								)}
							</Menu.Item>
						</div>
					))}
				</Menu.Items>
			</Transition>
		</Menu>
	)
}

export default NotificationBadged
