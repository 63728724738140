export const filterComponentData = (
	dataArr: Array<Api.Material | Api.Equipment | any>,
	filterKey: string
) => {
	return dataArr.filter(
		(data: Api.Material | Api.Equipment) =>
			data.myoctans_display === filterKey || data.myoctans_display === 'both'
	)
}

export const filterTasks = (
	dataArr: Array<Api.Task | any>,
	filterKey: number
) => {
	if (filterKey === 0) {
		return dataArr;
	}
	var result =  dataArr.filter(
		(data: Api.Task) =>
			data.project_id === filterKey
	);
	return result;
}

export const hoursToTime = (hours: number) => {
	const h = Math.floor(hours).toString().padStart(2, '0');
	const m = Math.round((hours - Math.floor(hours)) * 60).toString().padStart(2, '0');
	return `${h}:${m}`;
}