import { getAddresses, getBorers, getDrills, getEquipments, getVehicles } from '@/api/resources'
import globalContext from '@/context'
import { filterComponentData } from '@/utils'
import { useContext, useEffect, useState } from 'preact/hooks'
import { useTranslation } from 'react-i18next'

type Props = { filterkey?: string, prefered?: string} & JSX.HTMLAttributes<HTMLSelectElement>

function makeSelect<T extends Api.Resource>(
	getItems: () => Promise<T[]>,
	baseProps: JSX.HTMLAttributes<HTMLSelectElement> = {}
): FC<Props> {
	return ({ children, ...p }: Props) => {
		const { t } = useTranslation('forms')
		const [, actions] = useContext(globalContext)
		const [items, setItems] = useState<T[]>([])

		useEffect(() => {
			getItems()
				.then((items: T[]) => {
					if (p.filterkey) {
						items = filterComponentData(items, p.filterkey)
					}
					setItems(items)
				})
				.catch(err => {
					actions.toastError(err?.message)
				})
		}, [])

		return (
			<div>
				<label>{p.label}{p.required && <span class='text-red-600'>&nbsp;*</span>}</label>
				<select {...baseProps} {...p}>
					<option value=''>{t('select_an_option')}</option>
					{items.map(item => (
						<option selected={p.prefered === 'name' ? item.name == p.defaultValue : item.id.toString() == p.defaultValue} value={p.prefered === 'name' ? item.name : item.id}>{item.name}</option>
					))}
				</select>
			</div>
		)
	}
}

export const VehicleSelect = makeSelect<Api.Equipment>(getVehicles)
export const BorerSelect = makeSelect<Api.Equipment>(getBorers)
export const AddressSelect = makeSelect<Api.Address>(getAddresses)
export const EquipmentSelect = makeSelect<Api.Equipment>(getEquipments)
export const DrillSelect = makeSelect<Api.Equipment>(getDrills)