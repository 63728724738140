import Btn from '@/components/forms/Btn'
import { getBlob } from '@/helpers'
import classNames from 'classnames'
import { StateUpdater, useEffect, useRef, useState } from 'preact/hooks'
import { useTranslation } from 'react-i18next'
import type ReactSignatureCanvas from 'react-signature-canvas'
import Canvas from 'react-signature-canvas'
import ArrowLeftIcon from '~icons/bi/arrow-left'
import FullscreenIcon from '~icons/bi/arrows-fullscreen'

type Props = {
	class?: string
	label: string
	bgImage?: string
	setBlob: StateUpdater<Blob | undefined>
	drawing?: string
	type?: 'matrix' | 'blasting'
	disabled?: boolean
}

const Drawing: FC<Props> = ({ class: className, ...p }) => {
	const { t } = useTranslation('forms')
	const canvasRef = useRef<ReactSignatureCanvas>(null)
	const [fullscreen, setFullscreen] = useState<boolean>(false)

	useEffect(() => {
		if (!fullscreen) return
		if (p.disabled) canvasRef.current?.off()
		else canvasRef.current?.on()
		draw(p.drawing)
	}, [fullscreen])

	function draw(drawing?: string) {
		canvasRef.current?.clear()
		const canvas = canvasRef.current!.getCanvas()
		const ctx = canvas.getContext('2d')!
		canvas.height = innerHeight
		canvas.width = innerWidth
		if (drawing && p.disabled) {
			const img = new Image()
			img.src = drawing
			img.onload = () => ctx.drawImage(img, 0, 0)
			return
		}

		switch (p.type) {
			case 'matrix':
				ctx.lineWidth = 0.5
				for (let i = 0; i < canvas.width; i += 10) {
					ctx.beginPath()
					ctx.moveTo(i, 0)
					ctx.lineTo(i, canvas.height)
					ctx.strokeStyle = i % 50 === 0 ? '#aaaaaa' : '#eeeeee'
					ctx.stroke()
				}
				for (let i = 0; i < canvas.height; i += 10) {
					ctx.beginPath()
					ctx.moveTo(0, i)
					ctx.lineTo(canvas.width, i)
					ctx.strokeStyle = i % 50 === 0 ? '#aaaaaa' : '#eeeeee'
					ctx.stroke()
				}
				break
			case 'blasting':
				const img = new Image()
				img.src = '/blasting_diagram.png'
				img.onload = () => {
					canvasRef.current?.clear()
					const dy = canvas.height / 2 - img.height / 2
					const dx = canvas.width / 2 - img.width / 2
					ctx.drawImage(img, dx, dy)
				}
				break
		}
	}

	const handleSave = async () => {
		if (!canvasRef.current?.isEmpty()) {
			const canvas = canvasRef.current!.getTrimmedCanvas()
			const blob = await getBlob(canvas)
			p.setBlob(blob)
		}
		setFullscreen(false)
	}

	return (
		<>
			<Btn class={classNames('w-full', className)} onClick={() => setFullscreen(true)}>
				<div>{p.label}</div>
				<FullscreenIcon />
			</Btn>
			{fullscreen && (
				<div class='relative inset-0 h-full w-full touch-manipulation'>
					<div class='fixed inset-x-8 top-4 z-20 flex items-center gap-4'>
						<ArrowLeftIcon
							onClick={() => setFullscreen(false)}
							class='mr-auto h-8 w-8 cursor-pointer text-teal-700'
						/>
						<Btn danger onClick={draw as any}>
							{t('daily_report:clear')}
						</Btn>
						<Btn onClick={handleSave}>{t('forms:save')}</Btn>
					</div>
					<Canvas
						ref={canvasRef}
						canvasProps={{
							className: 'fixed inset-0 bg-white',
							width: innerWidth,
							height: innerHeight,
						}}
					/>
				</div>
			)}
		</>
	)
}

export default Drawing

export function useDrawing(name: string, filename: string) {
	const [blob, setBlob] = useState<Blob | undefined>()
	const append = (fd: FormData) => {
		if (blob) fd.append(name, blob, filename)
	}

	return { setBlob, append }
}
