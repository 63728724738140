export const env = import.meta.env
export const PROTECTED = env.DEV || env.MODE === 'staging'

export const API_DATETIME_FMT = 'yyyy-MM-dd TT'
export const INPUT_DATETIME_FMT = "yyyy-MM-dd'T'T"
export const INPUT_DATETIME_FMT_ENTRY = 'yyyy-MM-dd'

export const SECONDS = 1000
export const MINUTES = SECONDS * 60

export const MEASURE_UNITS = {
	feetOrMeters: { meters: 1, feet: 0.3048 },
	inchesOrMillimeters: { millimeters: 1, inches: 0.0393701 },
	litersOrGallons: { liters: 1, gallons: 4.54609 },
}