import Input from "@/components/forms/Input"
import Radio from "@/components/forms/Radio"
import type { ChangeEvent } from "preact/compat"
import Options from "@/components/forms/Options"
import { useTranslation } from 'react-i18next'
import { yesNoChoices } from "./utils"

export type HoleDescription = {
    [key: string]: any
    hole_number: string
    trephine_number: string
    is_hole_number_changed: boolean
    depth_planned: number
    matrix_input: number
    depth_drilled: number
    depth_measured: number
    water: string
    angle: number
    clean: number
    clean_reforated: string
    hole_description: string
    remote: string
    broken_steel: number
    broken_rocks: number
    time_casing: number
    casing_meters: number
    comment: string
    diameter_value: number
    diameter_units: string
}

type Props = {
    hole: HoleDescription
    index: number
    handleInput: (index: number, field: string, value: string | number) => void
    borers: Api.Equipment[] | undefined
    units: string
}

const HoleDescriptionForm: FC<Props> = ({hole, index, handleInput, ...p}) => {
    const { t } = useTranslation('driller')
    const remoteChoices = yesNoChoices(t)

    return (
        <>
            <Input
                className='span6'
                type='text'
                value={hole.hole_number}
                name={`form[mine][${index}][hole_number]`}
                label={t('hole_number')}
                onChange={(e: ChangeEvent) => handleInput(index, 'hole_number', (e.target as HTMLInputElement).value)}
            />
            <div>
				<label>{t('trephine_number')}</label>
				<select name={`form[mine][${index}][trephine_number]`}  onChange={(e: ChangeEvent) => handleInput(index, 'trephine_number', (e.target as HTMLInputElement).value)}>
					<option value=''>{t('forms:select_an_option')}</option>
					{p.borers?.map(borer => (
						<option selected={borer.name == hole.trephine_number} value={borer.name}>{borer.name}</option>
					))}
				</select>
			</div>
            <label>
                {t('drill_diameter')}
                <div class='flex gap-x-4'>
                    <input
                        type='number'
                        inputMode='decimal'
                        value={hole.diameter_value}
                        name={`form[mine][${index}][diameter_value]`}
                        step='any'
                        min='0'
                        onBlur={(e: ChangeEvent) => handleInput(index, 'diameter_value', parseFloat((e.target as HTMLInputElement).value))}
                    />
                    <select class='w-32' name={`form[mine][${index}][diameter_units]`} onChange={(e: ChangeEvent) => handleInput(index, 'diameter_units', (e.target as HTMLInputElement).value)}>
                        <option selected={hole.diameter_units == 'milimeters'} value='milimeters'>{t('forms:millimeters')}</option>
                        <option selected={hole.diameter_units == 'inches'}  value='inches'>{t('forms:inches')}</option>
                    </select>
                </div>
            </label>
             <label class={'span-6'}>
                {t('clean_reforated')}
                <select name={`form[mine][${index}][clean_reforated]`} onChange={(e: ChangeEvent) => handleInput(index, 'clean_reforated', (e.target as HTMLInputElement).value)}>  
                    <Options prefered="name" d={hole.clean_reforated} t={t} options={['N', 'R', 'F']}/>
                </select>
            </label>
            <label class="uppercase mb-0 mr-5">{p.units == 'feet' ? t('depth_f') : t('depth_m')}</label>
            <Input
                className='span2-nb'
                type='number'
                decimal
                value={hole.depth_planned}
                name={`form[mine][${index}][depth_planned]`}
                label={t('planned')}
                onBlur={(e: ChangeEvent) => handleInput(index, 'depth_planned', parseFloat((e.target as HTMLInputElement).value))}
            />
            <Input
                className='span2-nb'
                type='number'
                decimal
                value={hole.depth_drilled}
                name={`form[mine][${index}][depth_drilled]`}
                label={t('drilled')}
                onBlur={(e: ChangeEvent) => handleInput(index, 'depth_drilled', parseFloat((e.target as HTMLInputElement).value))}
            />
            <Input
                className='span2-nb'
                type='number'
                decimal
                value={hole.depth_measured}
                name={`form[mine][${index}][depth_measured]`}
                label={t('measured')}
                onBlur={(e: ChangeEvent) => handleInput(index, 'depth_measured', parseFloat((e.target as HTMLInputElement).value))}
            />

            <Input
                className='span3-nb'
                type='text'
                value={hole.angle}
                decimal
                name={`form[mine][${index}][angle]`}
                label={t('angle')}
                onBlur={(e: ChangeEvent) => handleInput(index, 'angle', parseFloat((e.target as HTMLInputElement).value))}
            />
            <Input
                className='span3-nb'
                type='number'
                decimal
                value={hole.clean}
                name={`form[mine][${index}][clean]`}
                label={t('clean')}
                onBlur={(e: ChangeEvent) => handleInput(index, 'clean', parseFloat((e.target as HTMLInputElement).value))}
            />

            <div class="span3 flex mt-1 mb-1">
                <label class="uppercase mb-0 mr-5">{t('remote')}</label>
                <div class="justify-end">
                    <Radio
                        name={`form[mine][${index}][remote]`}
                        choices={remoteChoices}
                        checked={hole.remote == '' ? t('no') : hole.remote}
                        prefered='name'
                        onChange={(value: string) => handleInput(index, 'remote', value)}
                    />
                </div>
            </div>

            <div class="span3 flex mt-1 mb-1">
                <label class="uppercase mb-0 mr-5">{t('water')}</label>
                <div class="justify-end">
                    <Radio
                        name={`form[mine][${index}][water]`}
                        choices={remoteChoices}
                        checked={hole.water == '' ? t('no') : hole.water}
                        prefered='name'
                        onChange={(value: string, name?: string) => handleInput(index, 'water', value)}
                    />
                </div>
            </div>

            <Input
                className='span3-nb'
                type='number'
                decimal
                value={hole.broken_steel}
                name={`form[mine][${index}][broken_steel]`}
                label={t('broken_steel')}
                onBlur={(e: ChangeEvent) => handleInput(index, 'broken_steel', parseFloat((e.target as HTMLInputElement).value))}
            />

            <Input
                className='span3-nb'
                type='number'
                decimal
                value={hole.broken_rocks}
                name={`form[mine][${index}][broken_rocks]`}
                label={t('broken_rocks')}
                onBlur={(e: ChangeEvent) => handleInput(index, 'broken_rocks', parseFloat((e.target as HTMLInputElement).value))}
            />

            <label>
                {t('result')}
                <select name={`form[mine][${index}][hole_description]`} onChange={(e: ChangeEvent) => handleInput(index, 'hole_description', (e.target as HTMLInputElement).value)}>  
                    <Options d={hole.hole_description} t={t} options={['NBT - Non Break Through - Dans le roc solide', 'TC - Tout Cassé - Dans la roche cassée', 'BT - Break Through - Défonçant dans le vide', 'BMT - Break Through Muck - Défonçant dans la roche cassées']}/>
                </select>
            </label>
            <Input
                className='span6'
                decimal
                value={hole.time_casing}
                step='0.25'
                name={`form[mine][${index}][time_casing]`}
                label={t('time_casing')}
                onBlur={(e: ChangeEvent) => handleInput(index, 'time_casing', (Math.round((+(e.target as HTMLInputElement).value as number) / 0.25)) * 0.25)}
            />

            <Input
                className='span6'
                decimal
                value={hole.casing_meters}
                name={`form[mine][${index}][casing_meters]`}
                label={t('casing_meters')}
                onBlur={(e: ChangeEvent) => handleInput(index, 'casing_meters', parseFloat((e.target as HTMLInputElement).value))}
            />

            <Input
                className='span6'
                type='text'
                value={hole.comment}
                name={`form[mine][${index}][comment]`}
                label={t('forms:comments')}
                onChange={(e: ChangeEvent) => handleInput(index, 'comment', (e.target as HTMLInputElement).value)}
            />
        </>

    )
}

export default HoleDescriptionForm