import { postReceipt } from '@/api/purchase'
import Checkbox from '@/components/forms/Checkbox'
import Comments from '@/components/forms/Comments'
import Drawing, { useDrawing } from '@/components/forms/Drawing'
import Form from '@/components/forms/Form'
import { EmployeeSelect, ProjectSelect } from '@/components/forms/FuzzySelect'
import Input from '@/components/forms/Input'
import { useTranslation } from 'react-i18next'
import globalContext from '@/context'
import { useContext } from 'preact/hooks'

const SendReceipt: FC<{ path: string }> = () => {
	const { t } = useTranslation('send_receipt');
	const [, actions] = useContext(globalContext);
	const { setBlob: setSignature, append: appendSignature } = useDrawing(
		'signature',
		'signature.png'
	)
	const submitArgs = {
		submit: postReceipt,
		async process(fd: FormData) {
			appendSignature(fd)
		},
		async onSuccess() {
			actions.toastInfo(t('receipt_submitted'))
			history.back()
		},
	}

	return (
		<Form submitArgs={submitArgs}>
			<Input name='number' required label={t('receipt_number')} />
			<Input name='price' units='dollars' label={t('forms:price')} />
			<ProjectSelect name='project_id' required label={t('forms:project')} />
			<Input name='image' type='file' accept='image/*' label={t('forms:picture')} />
			<EmployeeSelect name='supervisor' required label={t('supervisor')} />
			<Input name='invoice_date' type='date' label={t('forms:invoice_date')} />
			<Comments name='note' />
			<Drawing label={t('forms:signature')} setBlob={setSignature} />
			<Checkbox className='mt-6' name='generate_po' type='checkbox' label={t('forms:generate_po')} />
		</Form>
	)
}
export default SendReceipt
