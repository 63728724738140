import { useEffect, useState, useContext } from 'preact/hooks';
import Comments from '@/components/forms/Comments'
import Input from '@/components/forms/Input'
import globalContext from '@/context'
import { filterComponentData } from '@/utils';
import { sortBy } from 'lodash-es'
import { useTranslation } from 'react-i18next'
import { getSeismograph } from '@/api/resources';

type Props = { d: any; i: number, filterkey: string }
const Seismograph: FC<Props> = ({ d, i, ...p }) => {
	const { t } = useTranslation('shooting_log')
	const [ seismographs, setSeismographs ] = useState<Api.Equipment[]>([])
	const [, actions] = useContext(globalContext)
	useEffect(() => {
		getSeismograph()
			.then((items: Api.Equipment[]) => {
				items = filterComponentData(items, p.filterkey)
				setSeismographs(items)
			})
			.catch(err => {
				actions.toastError(err?.message)
			})
	}, [])
	return (
		<div class='fieldset'>
			<label className='span3'>
				{t('seismo_no')}
				<select name={`form[seismograph][${i}][seismograph_no]`} defaultValue={d?.seismograph_no}>
					<option value=''>{t('forms:select_an_option')}</option>
					{sortBy(seismographs, 'name').map((seismograph, index) => (
						<option key={seismograph.name + index} value={seismograph.name}>{seismograph.name}</option>
					))}
				</select>
			</label>
			<Input
				className='span3'
				name={`form[seismograph][${i}][event_no]`}
				defaultValue={d?.event_no}
				label={t('event_no')}
				integer
			/>
			<Input
				name={`form[seismograph][${i}][location]`}
				defaultValue={d?.location}
				label={t('seismo_location')}
			/>
			<Input
				className='span2'
				name={`form[seismograph][${i}][dist]`}
				defaultValue={d?.dist}
				label={t('dist')}
				decimal
			/>
			<Input
				className='span2'
				name={`form[seismograph][${i}][mm_sec]`}
				defaultValue={d?.mm_sec}
				label={t('mm_sec')}
				decimal
			/>
			<Input
				className='span2'
				name={`form[seismograph][${i}][db]`}
				defaultValue={d?.db}
				label={t('db')}
				decimal
			/>
			<Comments name={`form[seismograph][${i}][comment]`} defaultValue={d?.comment} />
		</div>
	)
}
export default Seismograph
