import Report from '@/components/Report'
import Comments from '@/components/forms/Comments'
import Input from '@/components/forms/Input'
import { VehicleSelect } from '@/components/forms/select'
import { useFilledReport } from '@/hooks'
import { useTranslation } from 'react-i18next'

const DriverReport: FC<ReportProps> = p => {
	const { t } = useTranslation('driver')
	const r = useFilledReport(p.report_id)

	return (
		<Report type='driver' report={r} disabled={p.disabled === 'disabled'}>
			<input type='hidden' name='version' defaultValue='2' />
			<Input
				autocomplete='on'
				required
				name='form[departure]'
				defaultValue={r?.form.departure}
				label={t('departure')}
			/>
			<Input
				autocomplete='on'
				required
				name='form[arrival]'
				defaultValue={r?.form.arrival}
				label={t('arrival')}
			/>
			<Input
				className='span3'
				units='km'
				name='form[km]'
				defaultValue={r?.form.km}
				label={t('total_km')}
			/>
			<Input
				className='span3'
				units='hours'
				name='form[hours]'
				defaultValue={r?.form.hours}
				label={t('total_hours')}
			/>
			<VehicleSelect
				name='form[vehicle]'
				defaultValue={r?.form.vehicle}
				label={t('vehicle')}
				filterkey={'forms'}
			/>
			<Input
				className='span3'
				name='form[license_plate]'
				defaultValue={r?.form.license_plate}
				label={t('license_plate')}
			/>
			<Input
				name='form[object_transported]'
				defaultValue={r?.form.object_transported}
				label={t('object_transported')}
			/>
			<Comments name='form[comment]' defaultValue={r?.form.comment} />
		</Report>
	)
}
export default DriverReport
